import React from 'react';
import { post } from "../../utils/request";
import { Col, Form, Row } from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import mfaErrorHandler from "./MfaErrorHandler";

class MfaDeleteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                Code: "",
            },
            errorMessage: '',
            errors: {},
            isMfaError: false,
            mfaToDelete: false,
            mfaFailed: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMfaErrors = this.handleMfaErrors.bind(this);
        this.hasError = this.hasError.bind(this);
        this.resendLink = this.resendLink.bind(this);
        this.showError = this.showError.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    handleMfaErrors(mfaRes) {
        const mfaFailed = mfaRes.mfaFailed;
        const errorMessage = mfaRes.message;
        const resendLinkBool = mfaRes.resendLinkBool;
        this.setState({mfaFailed: mfaFailed});
        this.setState({errorMessage: errorMessage});
        this.setState({resendLinkBool: resendLinkBool});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            this.setState({isMfaError: true});
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            error.message = "Please enter a valid 6-digit " + field.toLowerCase();
        }
    }

    clearAllErrors() {
        this.setState({isMfaError: false});
        this.setState({message: ""});
        this.setState({errorMessage: ""});
        this.setState({errors: ""});
    }

    isCodeCorrect(code) {
        if (code.length !== 6) {
            this.setState({errorMessage: "Please enter a valid 6-digit code"});
            this.setState({isMfaError: true});
            return false;
        }
        this.clearAllErrors();
        return true;
    }

    resetCode() {
        const data = this.state.data;
        data.Code = '';
        this.setState({data: data});
    }

    resendLink() {
        this.setState({resendLinkBool: false});
        this.clearAllErrors();
        this.resetCode();
        this.handleSubmit();
    }

    async handleSubmit(event) {
        if (event) { // TODO - remove if possible
            event.preventDefault();
        }
        if (!this.state.resendLinkBool && !this.isCodeCorrect(this.state.data.Code) && this.state.mfaToDelete) {
            return;
        }
        this.clearAllErrors();
        const [res, err] = await post(this, "/auth/mfa/phone/delete", this.state.data);
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 412:
                        if(err.response.data.StatusText === "mfa_code_required") {
                            this.setState({errorMessage: ""});
                        }
                        let mfaRes = mfaErrorHandler(err.response.data.StatusText);
                        this.handleMfaErrors(mfaRes);
                        this.setState({mfaToDelete: true});
                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors;
                        this.setState({errorMessage: "Please enter a valid code"});
                        this.setState({errors: errors});
                        return;
                    case 400:
                        this.setState({errorMessage: "Please enter a valid code"});
                        this.setState({errors: ""});
                        return;
                    case 500:
                        this.setState({errorMessage: "Something went wrong, please try again"});
                        return;
                }
            }
            this.setState({errorMessage: "Error: " + err});
            return;
        }

        this.setState({errors: ""});
        this.props.modalClose();

        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    render() {
        const { data, errorMessage, isMfaError, mfaFailed, mfaToDelete, resendLinkBool  } = this.state;
        const { MfaPhone, modalClose } = this.props;

        return <>
            {errorMessage && <Alert variant="danger-text">{errorMessage}</Alert>}
            <Form className="form-fieldnames verify-code-form" onSubmit={this.handleSubmit}>
                {!mfaFailed &&
                    <p>Are you sure you want to remove your phone used for two-factor authentication?<br/><br/>
                        <b>By doing this only username and password will be required to log on, no phone verification is
                            asked anymore.</b><br/><br/>
                        By clicking <b>YES</b> a verification code will be sent to your phone <b>{MfaPhone}</b> to confirm this
                        action.
                    </p>
                }
                {mfaToDelete && !mfaFailed &&
                    <Form.Group as={Row} controlId="formCode">
                        <Form.Label column sm="4">Code</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                autoFocus
                                type="text"
                                name="Code"
                                value={data.Code}
                                isInvalid={isMfaError}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Form.Group>
                }
                {resendLinkBool && <Row><Button className="btn-lnk mx-auto mb3" label="Resend code" onClick={this.resendLink}/></Row>
                }
                <div className="d-flex justify-content-end">
                    <Button className="cancel-outline btn btn-md mr3" label="Cancel" onClick={modalClose} type={"button"}/>
                    {!mfaToDelete && !mfaFailed &&
                        <Button className="primary btn btn-md" label="YES" type={"submit"}/>
                    }
                    {mfaToDelete && !mfaFailed &&
                        <Button className="primary btn btn-md" label="Confirm" type={"submit"}/>
                    }
                </div>
            </Form>
        </>
    }
}

export default MfaDeleteForm;