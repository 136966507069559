import React from 'react';
import {get, post} from '../../utils/request';
import Alert from "../../component/Alert/Alert";
import Container from "../Container/Container";
import {CountryByCode} from "../CountryByCode";
import DropdownSelector from "../DropdownSelector";
import {Col, Form, Row} from 'react-bootstrap';
import countryList from 'react-select-country-list'
import "./BillingInformation.scss";


const countries = countryList().getData();

class BillingInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertClass: props.alertClass,
            data: {
                Address: '',
                Company: '',
                countryCode: '',
                Name: '',
                RegistrationNumber: '',
                VatNumber: '',
            },
            errorMessage: '',
            errors: {},
            history: props.history,
            hrefLink: props.hrefLink,
            lastSubmitTime: null,
            loadingSuccess: false,
            showData: {
                Email: '',
                Paid: '',
            },
            successMessage: '',
            variant: props.variant,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this);
        this.hasError = this.hasError.bind(this);
        this.loadData = this.loadData.bind(this);
        this.showError = this.showError.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            this.setState({errorMessage: "Error: " + err});
            this.setState({errors: {}})
            return
        }
        const data = res.data;
        const showData = {
            email: data.email,
            paid: data.paid,
        };
        delete data.email;
        delete data.paid;

        this.setState({data: data});
        this.setState({showData: showData});
        this.setState({loadingSuccess: true});
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevState, prevProps) { // IDE error on missing parameters ignored on purpose
        if (this.props.lastSubmitTime && this.props.lastSubmitTime !== this.state.lastSubmitTime) {
            this.setState({lastSubmitTime: this.props.lastSubmitTime});
            await this.handleSubmit();
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    handleCountryCodeChange(newValue) {
        const data = this.state.data;
        data.countryCode = newValue;
        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return;
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            error.message = "Please enter a " + field.toLowerCase();
        }
    }

    async handleSubmit(event) {
        if (event) { // TODO - remove if possible
            event.preventDefault();
        }

        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});

        const [res, err] = await post(this, "/auth/settings/edit", this.state.data);
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 422:
                        const errors = err.response.data.ValidationErrors;
                        this.setState({errors: errors});
                        return;
                    case 400:
                        this.setState({errorMessage: "" + err});
                        return;
                }
            }
            this.setState({errorMessage: "Error: " + err});
            return;
        }

        this.setState({successMessage: "Settings saved"});
        this.setState({errors: ""});

        window.scrollTo({top: 0, behavior: 'smooth'});

        if(this.state.hrefLink) {
            this.props.history.push(this.state.hrefLink);
        }
    }

    render() {
        const { alertClass, data, errorMessage, loadingSuccess, showData, successMessage, variant } = this.state;

        return <>
            {successMessage && <Alert className={alertClass} variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert className={alertClass} variant="danger">{errorMessage}</Alert>}
            {loadingSuccess &&
            <>
                <Form onSubmit={e => this.handleSubmit(e)}>
                    <Container variant={variant}>
                    <h2>User details</h2>
                    <Form.Group as={Row} controlId="formEmail">
                        <Form.Label column sm="2">Email</Form.Label>
                        <Col sm="10">
                            <p>{showData.email}</p>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextName">
                        <Form.Label column sm="2">Name*</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="name"
                                defaultValue={data.name}
                                isInvalid={this.hasError("name")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback className="form-validation-error" type="invalid">{this.showError("name")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    </Container>
                    <Container variant={variant}>
                    <h2>Billing details</h2>
                    <Form.Group as={Row} controlId="formCountryCode">
                        <Form.Label column sm="2">Country*</Form.Label>
                        <Col>
                            <DropdownSelector
                                data={countries}
                                defaultValue={{
                                    value: data.countryCode,
                                    label: CountryByCode(data.countryCode)
                                }}
                                onChange={this.handleCountryCodeChange.bind(this)}
                                hasError={this.hasError("countryCode")}
                            />
                            {this.hasError("countryCode") &&
                            <div className="invalid-feedback block-display form-validation-error">Please select a country</div>
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextCompany">
                        <Form.Label column sm="2">Company</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="company"
                                defaultValue={data.company}
                                isInvalid={this.hasError("company")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid">{this.showError("company")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formAddress">
                        <Form.Label column sm="2">Address</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="address"
                                defaultValue={data.address}
                                isInvalid={this.hasError("address")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid">{this.showError("address")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formRegistrationNumber">
                        <Form.Label column sm="2">Registration number</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="registrationNumber"
                                defaultValue={this.state.data.registrationNumber}
                                isInvalid={this.hasError("registrationNumber")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid">{this.showError("registrationNumber")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formVatNumber">
                        <Form.Label column sm="2">VAT number</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="vatNumber"
                                defaultValue={data.vatNumber}
                                isInvalid={this.hasError("vatNumber")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid">{this.showError("vatNumber")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    </Container>
                </Form>
            </>
            }
        </>
    }
}

export default BillingInformation;