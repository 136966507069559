import React from 'react'
import Alert from "../../component/Alert/Alert";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";

class Confirm extends React.Component {
    render() {
        return <Layout activeMenu="">
            <div className="password-ctr">
                <h1>Confirm email</h1>
                <Alert variant="success">Email confirmed!</Alert>
                <div>
                    <p>You can sign in now.</p>
                    <Link className="lnk-btn primary btn-xl" href="/signIn" label="Sign in to your account"/>
                </div>
            </div>
        </Layout>;
    }
}

export default Confirm