import React from 'react';
import {get,post} from '../../utils/request';
import {Col, Form, Row} from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faBucket} from "@fortawesome/free-solid-svg-icons";
import DropdownSelector from "../../component/DropdownSelector";
class NewBucket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                location: '',
                versioningStatus: '',
                objectLock: false,
            },
            error: '',
            errors: {},
            locations: [],
            versioningDisabled: false,
            warning: '',
        };
        this.loadData = this.loadData.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleVersioningChange = this.handleVersioningChange.bind(this);
        this.handleObjectLockChange = this.handleObjectLockChange.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    handleLocationChange(newValue) {
        const data = this.state.data;
        data.location = newValue;
        this.setState({data: data});
    }

    handleNameChange(event) {
        const target = event.target;
        const value = target.value;

        const warnings = this.checkWarnings(value);
        if (warnings.length) {
            this.setState({warning: this.makeArrayToList(warnings)});
        } else {
            this.setState({warning: ''});
        }

        const errors = this.checkErrors(value);
        if (errors.length) {
            this.setState({error: this.makeArrayToList(errors)});
        } else {
            this.setState({error: ''});
        }

        const name = target.name;
        const data = this.state.data;
        data[name] = value;
        this.setState({data: data});
    }

    handleVersioningChange(event) {
        const target = event.target;
        const data = this.state.data;
        if (target.checked) {
            data.versioningStatus = 'Enabled';
        } else {
            data.versioningStatus = '';
        }
        this.setState({
            data: data
        });
    }

    handleObjectLockChange(event) {
        const target = event.target;
        const data = this.state.data;
        if (target.checked) {
            data.objectLock = true;
            data.versioningStatus = 'Enabled';
            this.setState({versioningDisabled: true})
        } else {
            data.objectLock = false;
            this.setState({versioningDisabled: false})
        }
        this.setState({
            data: data
        });
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/location");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({errors: {}})
            return
        }
        const cleanedLocations = [];
        res.data.forEach((item) => {
                cleanedLocations.push({label: item.uid, value: item.uid});
            }
        );
        this.setState({locations: cleanedLocations});
    }

    makeArrayToList(a) {
        return a.map((v,i) => <li key={i}>{v}</li>);
    }

    checkWarnings(value) {
        if(!value) {
            return [];
        }

        const warnings = [];

        if (value.includes('.')) {
            warnings.push('Dot in the bucket name is strongly discouraged because these buckets are accessible only path style.');
        }

        return warnings;
    }

    checkErrors(value) {
        if(!value) {
            return [];
        }

        const errors = [];

        if (!/^[a-z0-9\-\.]*$/.test(value)) {
            errors.push('Bucket name must contain only lowercase letters, numbers, hyphens and dots.');
        }

        if (!/^[a-z0-9]/.test(value)) {
            errors.push('Bucket name must start with a letter or a number.');
        }

        if (value.startsWith('xn--')) {
            errors.push('Bucket name must not begin with "xn--".');
        }

        if (value.length > 63) {
            errors.push('The bucket name must be less than 63 characters long.');
        }

        return errors;
    }

    checkErrorsSubmit(value) {
        const errors = this.checkErrors(value);

        if (value.length > 2 && !/[a-z0-9]$/.test(value)) {
            errors.push('Bucket name must end with a letter or a number.');
        }

        if (value.length < 3) {
            errors.push('The bucket name must be at least 3 characters long.');
        }

        if (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(value)) {
            errors.push('Bucket name must not be formatted like an IP address.');
        }

        return errors;
    }

    async handleSubmit(event) {

        event.preventDefault();
        
        this.setState({warning: ''});

        const warnings = this.checkWarnings(this.state.data.name);
        if (warnings.length) {
            this.setState({warning: this.makeArrayToList(warnings)});
        } else {
            this.setState({warning: ''});
        }

        const errors = this.checkErrorsSubmit(this.state.data.name);
        if (errors.length) {
            this.setState({error: this.makeArrayToList(errors)});

            return;
        } else {
            this.setState({error: ''});
        }

        if (!this.state.data.location) {
            this.setState({error: 'Please select a location'});

            return;
        }

        const [res, err] = await post(this, "/auth/bucket/create/in/" + this.state.data.location, this.state.data);

        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({error: "Failed to create bucket. Most probably due to naming rules, read below."});

                        return;
                    case 409:
                        this.setState({error: "Failed to create bucket. The name must be unique over all users of Storadera. " +
                                "Please choose a new name or add a suffix, for example '-2'."});
                        return;
                    case 500:
                        this.setState({error: "Failed to create bucket. The location is not available."});
                        return;
                }
            }
            this.setState({error: "error: " + err});

            return;
        }
        this.props.history.push('/buckets')
    }

    render() {
        const { data, error, locations, versioningDisabled, warning } = this.state;

        if (!this.state.locations[0]) {
            return <div>Loading data...</div>
        }

        return <Layout activeMenu="/buckets" isLoggedIn={true}>
                <h1 className="main-heading">Create new bucket</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {warning && <Alert variant="warning">{warning}</Alert>}
            <Container>
            <Form onSubmit={e => this.handleSubmit(e)}>
                <h2>Bucket name</h2>
                <p className="ctr__info-text">Bucket is like a high level folder. The name needs to be globally unique. It means your preferred name may be already taken. Be creative!</p>
                <Form.Group as={Row} controlId="formBucketName" className="form-fieldnames">
                    <Form.Label column sm="2">Bucket name</Form.Label>
                    <Col sm="10">
                        <Form.Control type="text" name="name" onChange={this.handleNameChange} value={data.name}/>
                    </Col>
                </Form.Group>
                <h2>Bucket location</h2>
                <Form.Group as={Row} controlId="formLocation" className="form-fieldnames">
                    <Form.Label column sm="2">Bucket location</Form.Label>
                    <Col sm="10">
                        <DropdownSelector
                            data={locations}
                            defaultValue={{
                                value: "",
                                label: ""
                            }}
                            onChange={this.handleLocationChange.bind(this)}
                        />
                    </Col>
                </Form.Group>
                <h2>Bucket versioning</h2>
                <p className="ctr__info-text">When versioning is enabled, you can retrieve and restore any previous version of the objects in the created bucket. Multiple versions count towards storage usage.</p>
                <Form.Group as={Row} controlId="formEnableVersioning" className="form-fieldnames">
                    <Form.Label column sm="2">Enable bucket versioning</Form.Label>
                    <Col sm="10">
                        <Form.Check disabled={versioningDisabled} name="versioningStatus" onChange={this.handleVersioningChange} checked={data.versioningStatus}/>
                    </Col>
                </Form.Group>
                <h2>Object lock</h2>
                <p className="ctr__info-text">Enabling Object Lock will enable you to lock objects for a given period in that bucket. This will also enable versioning. Object lock cannot be disabled after bucket creation.</p>
                <Form.Group as={Row} controlId="formEnableObjectLock" className="form-fieldnames">
                    <Form.Label column sm="2">Enable object lock</Form.Label>
                    <Col sm="10">
                        <Form.Check name="objectLockStatus" onChange={this.handleObjectLockChange}/>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
        <div className="d-flex justify-content-end">
            <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft} href={"/buckets"} label="Back"/>
            <Button className="btn primary" faIcon={faBucket} label="Create bucket" onClick={this.handleSubmit}/>
        </div>
            <br/>
            <Alert variant="info">
                <b>Bucket name rules</b>
                <ul>
                    <li>Must be from 3 to 63 characters long</li>
                    <li>
                        Bucket name can contain only:
                        <ul>
                            <li>Lowercase letters (<b>a-z</b>)</li>
                            <li>Numbers: (<b>0-9</b>)</li>
                            <li>Hyphen: (<b>-</b>)</li>
                            <li>Dot: (<b>.</b>)</li>
                        </ul>
                    </li>
                    <li>Must start and end with a letter or a number</li>
                    <li>Must not be formatted like an IP address (for example 102.168.3.4)</li>
                    <li>Must not begin with "xn--"</li>
                    <li>For best compatibility we recommend to avoid using dots (.)
                        because then you can't use virtual-host-style access
                    </li>
                </ul>
            </Alert>
        </Layout>;
    }
}

export default NewBucket