import React, {useMemo, useState} from 'react';
import {get, post} from '../../utils/request';
import {Col, Form, Row} from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faListCheck} from "@fortawesome/free-solid-svg-icons";
import DropdownSelector from "../../component/DropdownSelector";

class NewRule extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            buckets: [],
            data: {
                name: '',
                status: "enabled",
                targetBucket: '',
                targetLocationUid: '',
                replicateExisting: true,
            },
            error: '',
            errors: {},
            loading: true,
            location: location,
            name: this.props.match.params.name,
            warning: '',
        };
        this.loadBuckets = this.loadBuckets.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBucketChange = this.handleBucketChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.showError = this.showError.bind(this);
        this.hasError = this.hasError.bind(this);
    }

    async componentDidMount() {
        await this.loadBuckets();
        this.setState({loading: false});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    handleBucketChange(value) {
        const data = this.state.data;

        const targetBucketParts = value.split("|");

        data.targetLocationUid = targetBucketParts[0];
        data.targetBucket = targetBucketParts[1];
        this.setState({data: data});
    }

    handleStatusChange(event) {
        const data = this.state.data;
        data.status = event.target.value;
        this.setState({data: data});
    }

    async loadBuckets() {
        const [res, err] = await get(this, "/auth/bucket/list");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({buckets: res.data.Buckets});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({warning: ''});

        const [res, err] = await post(this, "/auth/bucket/" + this.state.name + "/in/" + this.state.location + "/replicationRule", this.state.data);

        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({error: "Failed to create rule."});

                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors;
                        this.setState({errors: errors});
                        this.setState({error: "Please fix errors in fields below!"});

                        return;
                    case 500:
                        this.setState({error: "Internal error"});

                        return;
                }
            }
            this.setState({error: "error: " + err});

            return;
        }
        this.props.history.push("/buckets/" + this.state.name + "/management?location=" + this.state.location)
    }

    hasError(field) {
        return this.state.errors[field];
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
    }

    errorTranslator(error, field) {
        switch (error.key) {
            case "length":
                switch (field) {
                    case "name":
                        error.message = "Please enter valid a name";
                        break;
                    case "targetBucket":
                        error.message = "Please choose target bucket";
                        break;
                }
                break;
            case "objectLockMismatch":
                switch (field) {
                    case "targetBucket":
                        error.message = "Source and destination buckets do not have same object lock configuration";
                        break;
                }
                break;
        }
    }

    render() {
        const {data, error, loading, warning} = this.state;

        const bucketsWithLocations = [];
        if (this.state.buckets) {
            this.state.buckets.forEach((item) => {
                if (item.name !== this.state.name || item.locationUid !== this.state.location) {
                    bucketsWithLocations.push({
                        value: item.locationUid + "|" + item.name,
                        label: item.locationUid + " / " + item.name
                    })
                }
            });
        }

        if (loading) {
            return <div>Loading data...</div>
        }

        return <Layout activeMenu="/buckets" isLoggedIn={true}>
            <h1 className="main-heading">Create new rule</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {warning && <Alert variant="warning">{warning}</Alert>}
            <Container>
                <Form onSubmit={e => this.handleSubmit(e)}>
                    <h2>Replication rule configuration</h2>
                    <p className="ctr__info-text">Rule name should have up to 255 characters. They should be only
                        English characters.</p>
                    <Form.Group as={Row} controlId="formName" className="form-fieldnames">
                        <Form.Label column sm="2">Name</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="name"
                                value={data.name}
                                onChange={this.handleChange}
                                isInvalid={this.hasError("name")}
                            />
                            <Form.Control.Feedback type="invalid">{this.showError("name")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formStatus">
                        <Form.Label column sm="2">Status</Form.Label>
                        <Col sm="10">
                            <div className="ctr__section-spacing">
                                <Form.Check
                                    id="Enabled"
                                    label="Enabled"
                                    value="enabled"
                                    name="status"
                                    type="radio"
                                    checked={this.state.data.status === "enabled"}
                                    onChange={this.handleStatusChange}
                                />
                                <Form.Check
                                    id="Disabled"
                                    label="Disabled"
                                    value="disabled"
                                    name="status"
                                    type="radio"
                                    checked={this.state.data.status === "disabled"}
                                    onChange={this.handleStatusChange}
                                />
                            </div>
                        </Col>
                    </Form.Group>
                    <h2>Source</h2>
                    <Form.Group as={Row} controlId="formSourceBucket" className="form-fieldnames">
                        <Form.Label column sm="2" className="pt-4">Source region</Form.Label>
                        <Col sm="10" className="pt-4">
                            <Form.Text name="targetLocationUid">{this.state.location}</Form.Text>
                        </Col>
                        <Form.Label column sm="2">Source bucket name</Form.Label>
                        <Col sm="10">
                            <Form.Text name="sourceName">
                                {this.state.name}
                                {this.hasError("Bucket") &&
                                <div className="invalid-feedback block-display form-validation-error">
                                    {this.showError("Bucket")}
                                </div>}
                            </Form.Text>
                        </Col>
                    </Form.Group>
                    <h2>Destination</h2>
                    <Form.Group as={Row} controlId="formTargetBucket" className="form-fieldnames">
                        <Form.Label column sm="2">Destination bucket</Form.Label>
                        <Col sm="10">
                            <DropdownSelector
                                id="targetBucket"
                                name="targetBucket"
                                data={bucketsWithLocations}
                                defaultValue={{
                                    value: "",
                                    label: ""
                                }}
                                onChange={this.handleBucketChange}
                            />
                            {this.hasError("TargetBucket") &&
                            <div className="invalid-feedback block-display form-validation-error">
                                {this.showError("TargetBucket")}
                            </div>}
                        </Col>
                    </Form.Group>
                    <h2>Replicate existing objects in bucket</h2>
                    <Form.Group as={Row} controlId="formReplicateExisting" className="form-fieldnames">
                        <Col sm="2">
                            <Form.Check
                                name="replicateExisting"
                                className="float-right"
                                onChange={this.handleChange}
                                checked={this.state.data.replicateExisting}/>
                        </Col>
                        <Form.Label column sm="10">Replicate all existing objects from the source bucket</Form.Label>
                    </Form.Group>
                </Form>
            </Container>
            <div className="d-flex justify-content-end">
                <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft}
                      href={"/buckets/" + this.state.name + "/management?location=" + this.state.location}
                      label="Back"/>
                <Button className="btn primary" faIcon={faListCheck} label="Create rule" onClick={this.handleSubmit}/>
            </div>
        </Layout>;
    }
}

export default NewRule