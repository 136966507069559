import React from 'react'
import {get} from '../../utils/request'

class UserEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSuccess: false,
            showData: {
                Email: '',
            },
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            this.setState({errorMessage: "Error: " + err});
            this.setState({errors: {}})
            return;
        }
        const data = res.data;
        const showData = {
            email: data.email,
        };
        this.setState({data: data});
        this.setState({showData: showData});
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        const { showData } = this.state;

        return <>
            <p>{showData.email}</p>
        </>
    }
}

export default UserEmail;
