import React from 'react';
import Menu from '../Menu/Menu';
import SideMenu from "../SideMenu/SideMenu.js";
import Page from "../Page/Page";
import UserEmailLoader from "../UserEmail/UserEmail";
import './Layout.scss';

const Layout = ({activeMenu, isLoggedIn, noMenu, children, layoutClass, width}) => {
    const userEmail = <UserEmailLoader/>;
    let menu = <Menu userEmail={userEmail}/>;
    let sideMenu = <SideMenu activeMenu={activeMenu}/>;
    let flex = "d-flex";

    if (!isLoggedIn) {
        flex =""
        sideMenu = "";
        menu = <Menu userEmail=""/>;
    }

    if (noMenu) {
        menu = "";
        sideMenu = "";
    }

    return <>
        {menu}
        <div className={flex}>
            {sideMenu}
            <Page layoutClass={layoutClass} width={width}>{children}</Page>
        </div>
    </>
}

export default Layout;