import React from 'react'
import "./Page.scss";

const Page = ({layoutClass, children}) => {

    return (
        <main className={layoutClass}>
            {children}
        </main>
    );
}

export default Page;