import React from 'react';
import {get,post} from '../../utils/request';
import {Col, Form, Row} from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faListCheck} from "@fortawesome/free-solid-svg-icons";
import DeleteRule from "./DeleteRule";

class ViewRule extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            buckets: [],
            data: {},
            error: '',
            errors: {},
            location: location,
            name: this.props.match.params.name,
            id: this.props.match.params.id,
            warning: '',
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/bucket/" + this.state.name + "/in/" + this.state.location + "/replicationRule/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }

        this.setState({data: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    async handleSubmit(event) {

        event.preventDefault();

        this.setState({warning: ''});

        const [res, err] = await post(this, "/auth/bucket/" + this.state.name + "/in/" + this.state.location + "/replication/" + this.state.id, this.state.data);

        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({error: "Failed to create rule. Most probably due to naming error."});

                        return;
                }
            }
            this.setState({error: "error: " + err});

            return;
        }
        this.props.history.push("/buckets/" + this.state.name + "/management?location=" + this.state.location)
    }

    render() {
        const { data, error, warning } = this.state;

        return <Layout activeMenu="/buckets" isLoggedIn={true}>
            <h1 className="main-heading">View rule {this.state.data.Name}</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {warning && <Alert variant="warning">{warning}</Alert>}
            <Container>
            <h2>Replication rule configuration</h2>
            <Form.Group as={Row} controlId="forName" className="form-fieldnames">
                <Form.Label column sm="2">Name</Form.Label>
                <Col sm="10">
                    <Form.Text name="status">{this.state.data.name}</Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formStatus">
                <Form.Label column sm="2">Status</Form.Label>
                <Col sm="10">
                    <Form.Text name="status">{this.state.data.status}</Form.Text>
                </Col>
            </Form.Group>
            <h2>Source bucket</h2>
            <Form.Group as={Row} controlId="formSourceBucket" className="form-fieldnames">
                <Form.Label column sm="2">Source bucket name</Form.Label>
                <Col sm="10">
                    <Form.Text name="sourceName">{this.state.name}</Form.Text>
                </Col>
                <Form.Label className="pt-4" column sm="2">Source region</Form.Label>
                <Col sm="10" className="pt-4">
                    <Form.Text name="targetLocationUid">{this.state.location}</Form.Text>
                </Col>
            </Form.Group>
            <h2>Destination</h2>
            <Form.Group as={Row} controlId="formBucket" className="form-fieldnames">
                <Form.Label column sm="2"> Destination bucket</Form.Label>
                <Col sm="10">
                    <Form.Text name="destinationBucket">{this.state.data.targetBucket}</Form.Text>
                </Col>
            </Form.Group>
            </Container>
            <div className="d-flex rule-btns">
                    <DeleteRule
                        history={this.props.history}
                        id={this.state.id}
                        rule={this.state.data.name}
                        location={this.state.location}
                        name={this.state.name}
                    />
                <div className="rule-btns-row">
                    <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft} href={"/buckets/" + this.state.name + "/management?location=" + this.state.location} label="Back"/>
                    <Link className="primary btn btn-md" faIcon={faListCheck} href={"/buckets/" + this.state.name + "/management/replication/" + this.state.data.id + "/edit?location=" + this.state.location} label="Edit rule"/>
                </div>
            </div>
        </Layout>;
    }
}

export default ViewRule