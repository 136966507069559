import React from 'react'
import Alert from "../../component/Alert/Alert";
import Layout from "../../component/Layout/Layout";
import './password.scss';

class PasswordSent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Layout activeMenu="/signIn">
            <div className="password-ctr">
                <h1>Reset link sent</h1>
                <Alert variant="success">Password reset link has been successfully sent to your email.</Alert>
                    <p>Please check your email and follow the link to reset your password.</p>
            </div>
        </Layout>;
    }
}

export default PasswordSent