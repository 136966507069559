import React from 'react'
import {CardNumberElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {get, post} from "../../utils/request";
import AddStripeForm from "./AddStripeForm";
import Form from "react-bootstrap/Form";
import {stripePromise} from "./stripePromise";
import Alert from "../../component/Alert/Alert";
import "./billing.scss";

class AddStripeInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            successMessage: "",
            data: {
                cardholderName: "",
            },
            inProgress: false,
            history: props.history,
            lastSubmitTime: null,
            alertClass: props.alertClass,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    async componentDidUpdate(prevProps,prevState) { // IDE error on missing parameters ignored on purpose
        if (this.props.lastSubmitTime && this.props.lastSubmitTime !== this.state.lastSubmitTime) {
            this.setState({lastSubmitTime: this.props.lastSubmitTime});
            await this.handleSubmit();
        }
        if (this.props.stripe !== prevProps.stripe || this.state.inProgress !== prevState.inProgress) {
            this.props.propagateButtonEnabled(!this.state.inProgress);
        }
    }

    async getSecret() {
        const [res, err] = await get(this, "/auth/payment/method/stripe/secret");
        if (err) {
            return [null, "error: " + err]
        }
        return [res.data.ClientSecret, null]
    }

    async confirmCard(stripe, elements, clientSecret) {
        if (!this.state.data.cardholderName) {
            return [null, "Please fill: Name on card"]
        }
        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: this.state.data.cardholderName,
                },
            }
        });

        if (result.error) {
            if (result.error.code === "setup_intent_authentication_failure") {
                return [null, "Authentication failure. Please try again!"]
            }
            const errorMessage = result.error.message ? result.error.message : "Error: " + JSON.stringify(result.error)
            return [null, errorMessage]
        }
        return [result.setupIntent.payment_method, ""]
    }

    async saveMethod(paymentMethodId) {
        const data = {
            PaymentMethodId: paymentMethodId,
        }
        const [res, err] = await post(this, "/auth/payment/methodAdd", data);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return "error: " + err
        }
        return "", null
    }

    async handleSubmit(event) {
        const {stripe, elements} = this.props
        if (!stripe || !elements) {
// Stripe.js has not yet loaded.
// Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }
        this.setState({errorMessage: ""});
        this.setState({successMessage: ""});
        this.setState({inProgress: true});

        const [clientSecret, paymentMethodInitError] = await this.getSecret();
        if (paymentMethodInitError) {
            this.setState({errorMessage: paymentMethodInitError});
            this.setState({inProgress: false});
            return;
        }

        const [methodId, confirmCardError] = await this.confirmCard(stripe, elements, clientSecret)
        if (confirmCardError) {
            this.setState({errorMessage: confirmCardError});
            this.setState({inProgress: false});
            return;
        }

        const saveMethodError = await this.saveMethod(methodId)
        if (saveMethodError) {
            this.setState({errorMessage: saveMethodError});
            this.setState({inProgress: false});
            return;
        }
        this.setState({inProgress: false});
        if(this.props.propagateSuccessConfirmation) {
            this.props.propagateSuccessConfirmation(true);
        }
        if(this.props.redirectAfterSubmit) {
            this.props.redirectAfterSubmit();
        }
    };

    render() {
        return <>

                <form className="add-stripe-wrapper" onSubmit={e => this.handleSubmit(e)} onChange={this.handleChange}>
                    {this.state.errorMessage && <Alert className={this.state.alertClass} variant="danger-text">{this.state.errorMessage}</Alert>}
                    {this.state.successMessage && <Alert className={this.state.alertClass} variant="success-text">{this.state.successMessage}</Alert>}
                    <AddStripeForm/>
                    <Form.Group  controlId="cardholderName">
                        <Form.Label>Name on card*</Form.Label>
                        <Form.Control
                            type="text"
                            name="cardholderName"
                            defaultValue={this.state.data.cardholderName}
                            onChange={this.handleChange}
                            placeholder="J. Smith"
                            className="card-name"
                        />
                    </Form.Group>
                    <Alert variant="info">
                        I authorise Storadera to send instructions to the financial institution that issued my
                        card to take
                        payments from my card account in accordance with the <a href="https://storadera.com/terms"
                                                                                target="_blank">terms of my agreement</a> with you.
                    </Alert>
                </form>
        </>
    }
}

export default function AddStripe(props) {
    return (
        <Elements stripe={stripePromise}>
            <br/>
            <ElementsConsumer>
                {({stripe, elements}) => (
                    <AddStripeInternal
                        stripe={stripe}
                        elements={elements}
                        history={props.history}
                        lastSubmitTime={props.lastSubmitTime}
                        propagateSuccessConfirmation={props.propagateSuccessConfirmation}
                        propagateButtonEnabled={props.propagateButtonEnabled}
                        redirectAfterSubmit={props.redirectAfterSubmit}
                        alertClass={props.alertClass}
                    />
                )}
            </ElementsConsumer>
        </Elements>
    );
}