import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Button.scss";

const Button = ({ className, faIcon, onClick, label, type }) => {

    let fontAwesomeIcon = <FontAwesomeIcon className="fa-icon" icon={faIcon}/>;

    if(!faIcon) {
        fontAwesomeIcon = "";
    }

    return (
        <button
            className={className}
            onClick={onClick}
            type={type}
        >
            {fontAwesomeIcon}
            {label}
        </button>
    );
}

export default Button;