import React from 'react';
import { del } from "../../utils/request";
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import { Modal, Row } from "react-bootstrap";
import {faTrash, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";

const modalTitle = "Delete rule";

class DeleteRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            location: props.location,
            id: props.id,
            name: props.name,
            show: false,
            successMessage: "",
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleShow() {
        this.setState({show: true});
    }

    handleClose() {
        this.setState({show: false});
    }

    async sendDeleteRequest(e) {
        this.setState({successMessage: ""});

        const [res, err] = await del(this, "/auth/bucket/" + this.state.name + "/in/" + this.state.location + "/replicationRule/" + this.state.id);
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 500:
                        this.setState({errorMessage: "Oops, something went wrong. Please try again later."});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({show: false});
        this.props.history.push("/buckets/" + this.state.name + "/management?location=" + this.state.location)
    }

    render() {
        const { errorMessage, show } = this.state;
        const { rule } = this.props;

        return (
            <>
                <Button className="btn secondary-outline" faIcon={faTrash} label="Delete" onClick={this.handleShow}/>
                <Modal
                    onHide={this.handleClose}
                    show={show}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        <p>Are you sure you want to delete rule <b>{rule}</b>?</p>
                        <Row className="float-right mr-auto">
                            <Button className="cancel-outline btn mr3" faIcon={faXmarkCircle} label="Cancel" onClick={this.handleClose}/>
                            <Button className="secondary btn" faIcon={faTrash} label="Delete" onClick={e => this.sendDeleteRequest(e)}/>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    };
}
export default DeleteRule;