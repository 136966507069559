import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Activate from "./page/activate/Activate";
import ActivateDone from "./page/activate/ActivateDone";
import Billing from "./page/billing/Billing";
import Confirm from './page/confirm/Confirm';
import ConfirmDone from './page/confirm/ConfirmDone';
import NotFound from './page/notFound/NotFound';
import PasswordDone from "./page/password/PasswordDone";
import PasswordExpired from "./page/password/PasswordExpired";
import PasswordForgot from "./page/password/PasswordForgot";
import PasswordReset from "./page/password/PasswordReset";
import PasswordSent from "./page/password/PasswordSent";
import Settings from "./page/settings/Settings";
import SecuritySettings from "./page/settings/SecuritySettings";
import SignIn from './page/signIn/SignIn';
import SignOut from './page/signOut/SignOut';
import Home from "./page/home/Home";
import InvoiceList from "./page/billing/InvoiceList";
import Key from "./page/key/Key";
import NewKey from "./page/key/NewKey";
import Buckets from "./page/buckets/Buckets";
import NewBucket from "./page/buckets/NewBucket";
import BucketProperties from "./page/buckets/BucketProperties";
import BucketManagement from "./page/buckets/BucketManagement";
import NewRule from "./page/buckets/NewRule";
import ViewRule from "./page/buckets/ViewRule";
import EditRule from "./page/buckets/EditRule";
import EditBucketVersioning from "./page/buckets/EditBucketVersioning";
import LandingStep from "./page/spacePremium/LandingStep";
import BillingStep from "./page/spacePremium/BillingStep";
import PaymentStep from "./page/spacePremium/PaymentStep";
import SuccessStep from "./page/spacePremium/SuccessStep";
import VoucherList from "./page/vouchers/VoucherList";
import VoucherNew from "./page/vouchers/VoucherNew";
import * as serviceWorker from './serviceWorker';

import ReactGA from 'react-ga';
import AddPaymentMethod from "./page/billing/AddPaymentMethod";
import AddPaymentMethodDone from "./page/billing/AddPaymentMethodDone";
import PendingAuthentication from "./page/billing/PendingAuthentication";
ReactGA.initialize('UA-138891054-3');
ReactGA.pageview(window.location.pathname + window.location.search);


const routing = (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/activate" component={Activate}/>
            <Route exact path="/activateDone" component={ActivateDone}/>
            <Route exact path="/billing" component={Billing} />
            <Route exact path="/billing/addPaymentMethod" component={AddPaymentMethod} />
            <Route exact path="/billing/addPaymentMethodDone" component={AddPaymentMethodDone} />
            <Route exact path="/billing/pendingAuthentication" component={PendingAuthentication} />
            <Route exact path="/billing/invoices" component={InvoiceList} />
            <Route exact path="/billing/vouchers" component={VoucherList} />
            <Route exact path="/billing/vouchers/new" component={VoucherNew} />
            <Route exact path="/buckets" component={Buckets}/>
            <Route exact path="/buckets/newBucket" component={NewBucket}/>
            <Route exact path="/buckets/:name/properties" component={BucketProperties}/>
            <Route exact path="/buckets/:name/properties/versioning/edit" component={EditBucketVersioning}/>
            <Route exact path="/buckets/:name/management" component={BucketManagement}/>
            <Route exact path="/buckets/:name/management/replication/newRule" component={NewRule}/>
            <Route exact path="/buckets/:name/management/replication/:id/view" component={ViewRule}/>
            <Route exact path="/buckets/:name/management/replication/:id/edit" component={EditRule}/>
            <Route exact path="/confirm" component={Confirm}/>
            <Route exact path="/confirmDone" component={ConfirmDone}/>
            <Route exact path="/key" component={Key} />
            <Route exact path="/key/NewKey" component={NewKey}/>
            <Route exact path="/password/done" component={PasswordDone}/>
            <Route exact path="/password/expired" component={PasswordExpired}/>
            <Route exact path="/password/forgot" component={PasswordForgot}/>
            <Route exact path="/password/reset" component={PasswordReset}/>
            <Route exact path="/password/sent" component={PasswordSent}/>
            <Route exact path="/settings" component={Settings}/>
            <Route exact path="/settings/security" component={SecuritySettings}/>
            <Route exact path="/signIn" component={SignIn}/>
            <Route exact path="/signOut" component={SignOut}/>
            <Route exact path="/SpacePremium" component={LandingStep}/>
            <Route exact path="/SpacePremium/2" component={BillingStep}/>
            <Route exact path="/SpacePremium/3" component={PaymentStep}/>
            <Route exact path="/SpacePremium/4" component={SuccessStep}/>
            <Route component={NotFound}/>
        </Switch>
    </BrowserRouter>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
