import React from 'react';
import { get } from '../../utils/request';
import { Row, Spinner} from 'react-bootstrap';
import Badge from "../../component/Badge/Badge";
import BucketsNav from "../../component/BucketsNav/BucketsNav";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faListCheck} from "@fortawesome/free-solid-svg-icons";
import './buckets.scss';
import Alert from "../../component/Alert/Alert";

class BucketManagement extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            data: {},
            rules: [],
            loading: true,
            location: location,
            name: this.props.match.params.name,
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        if(!this.state.location) {

            return;
        }

        let [res, err] = await get(this, "/auth/bucket/" + this.state.name + "/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({data: res.data});

        [res, err] = await get(this, "/auth/bucket/" + this.state.name + "/in/" + this.state.location + "/replicationRule");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});

            return;
        }
        this.setState({rules: res.data.Rules});

        this.setState({loading: false});
    }

    render() {
        const { data, errorMessage, loading, loadingSuccess, rules } = this.state;
        const isVersioned = data.versioning === "Enabled";

        return <Layout activeMenu="/buckets" isLoggedIn={true}>
            <h1 className="main-heading">Bucket {this.state.name}</h1>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {loading &&
                <>
                    <Spinner animation="border" variant="primary"/>Loading...
                    <br/><br/>
                </>
            }
            {!loading &&
                <>
                    <BucketsNav name={this.state.name} location={this.state.location}/>
                    <Container>
                        <h2>Replication rules</h2>
                        <Row>
                               <table className="table-ctr table-padding">
                                   <thead>
                                   <tr>
                                       <th>Name</th>
                                       <th>Status</th>
                                       <th>Destination bucket</th>
                                       <th>Destination region</th>
                                   </tr>
                                   </thead>
                                   <tbody>
                                   {rules && rules.map((value, index) => {
                                       let statusBadgeClass = "";

                                       switch(value.status) {
                                           case "disabled":
                                               statusBadgeClass = "badge-warning-filled";
                                               break;
                                           case "enabled":
                                               statusBadgeClass = "badge-success-filled";
                                               break;
                                           case "failed":
                                               statusBadgeClass = "badge-danger-filled";
                                               break;
                                       }

                                       return <tr key={index}>
                                           <td data-label={"Name"}>
                                               <Link className="lnk"  href={"/buckets/" + this.state.name + "/management/replication/" + value.id + "/view?location=" + this.state.location} label={value.name}/>
                                           </td>
                                           <td data-label={"Status"}><Badge className={statusBadgeClass} label={value.status}/></td>
                                           <td data-label={"Destination bucket"}>{value.targetBucket}</td>
                                           <td data-label={"Destination region"}>{value.targetLocationUid}</td>
                                       </tr>
                                   })}
                                   </tbody>
                               </table>
                        </Row>
                        <div className="text-center btn-create-rule">
                            {rules.length === 0 &&
                                <i>No replication rules</i>
                            }
                            {!isVersioned && <Alert variant="info">
                                Versioning must be enabled to add replication rule!
                            </Alert>}
                            {isVersioned && <Link
                                className="lnk-btn primary"
                                faIcon={faListCheck}
                                href={"/buckets/" + this.state.name + "/management/replication/newRule?location=" + this.state.location}
                                label="Create replication rule"
                            />}
                        </div>

                    </Container>
                    <div className="d-flex">
                        <Link className="lnk-btn primary-outline btn btn-right btn-md" faIcon={faArrowCircleLeft} href={"/buckets"} label="Back"/>
                    </div>
                </>
            }
        </Layout>;
    }
}

export default BucketManagement