import React from 'react';
import {get} from "../../utils/request";
import {Area, AreaChart, CartesianGrid, Tooltip, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import Container from "../../component/Container/Container";
import LocationSelector from "../LocationSelector/LocationSelector";
import "react-datepicker/dist/react-datepicker.css";
import {Alert, Col, Row, Spinner} from "react-bootstrap";
import "./UsageHistory.scss";

const filesize = require('filesize');

const chartAxisFormat = 'MM-DD';
const chartToolTipFormat = 'YYYY.MM.DD-H';
const periodFormat = 'D. MMM YYYY';

const dayjs = require('dayjs');

class UsageHistory extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            data: {
                Records: [],
            },
            location: location,
            errorMessage: "",
            loading: true,
            loadingSuccess: false
        };
        this.loadUsageHistory = this.loadUsageHistory.bind(this);
    }


    async componentDidMount() {
        await this.loadUsageHistory();
    }

    async loadUsageHistory() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/auth/account/usageHistory/in/" + this.state.location);
        if (err) {
            if (err.response && err.response.status && err.response.status === 404) {
                this.setState({errorMessage: "Data not available at this moment"});
            } else {
                this.setState({errorMessage: "error: " + err});
            }
            this.setState({loading: true});
            return
        }
        this.setState({data: res.data})
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    render() {
        const { data, errorMessage, loading, loadingSuccess, showData, usageLimit, usage } = this.state;

        function formatFunction(bytes) {
            return filesize(bytes);
        }

        function toolTipFormatter(bytes) {
            return ["Usage: " + formatFunction(bytes)];
        }

        function axisDateFormatter(date) {
            return dayjs(new Date(date.substr(0, 10))).format(chartAxisFormat);
        }

        function periodDateFormatter(date, format) {
            return dayjs(new Date(date.substr(0, 10))).format(format);
        }

        let records = data.Records;
        let chartData = [];
        let firstPeriod = "";
        let lastPeriod = "";
        let formattedPeriod = "";

        if (!loading) {
            let pushed = new Set();

            records.forEach((item) => {
                formattedPeriod = dayjs(new Date(item.Period.substr(0, 10) + " " + item.Period.substr(11, 2) + ":00")).format(chartToolTipFormat);

                if (!pushed.has(formattedPeriod)) {
                    pushed.add(formattedPeriod);
                    chartData.push({period: formattedPeriod, usage: item.Usage});
                }
            });

            lastPeriod = chartData[Object.keys(chartData)[Object.keys(chartData).length - 1]].period;
            firstPeriod = chartData[Object.keys(chartData)[0]].period;
        }

        return <>
            {loading &&
                <>
                    <Spinner animation="border" variant="primary"/> Loading usage history...
                    <br/><br/>
                </>
            }
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <Container className="chart-text d-flex">
                    <h2>Usage history</h2>
                    <LocationSelector></LocationSelector>
                        {loadingSuccess &&
                        <>
                        <Row>
                            <Col sm="2">
                                <label> Data usage over period:</label>
                            </Col>
                            <Col sm="10">
                                <p>{periodDateFormatter(firstPeriod, periodFormat)}-{periodDateFormatter(lastPeriod, periodFormat)}</p>
                            </Col>
                        </Row>
                        <div>
                            <ResponsiveContainer aspect={2.5}>
                                <AreaChart
                                    data={chartData}
                                    margin={{
                                        top: 30,
                                        right: 30,
                                        left: 0,
                                        bottom: 30
                                    }}
                                > <Tooltip
                                    wrapperStyle={{backgroundColor: "red"}}
                                    labelStyle={{color: "#115CA6"}}
                                    itemStyle={{color: "#003468"}}
                                    formatter={toolTipFormatter}
                                />
                                    <CartesianGrid strokeDasharray="1 1"/>
                                    <XAxis dataKey="period"
                                           fontFamily="sans-serif"
                                           scale="date"
                                           tickFormatter={axisDateFormatter}
                                           dy={5}
                                           minTickGap={20}
                                           tickLine={true}
                                           fontSize={12}
                                           tick={{fill: "#003468"}}

                                    />
                                    <YAxis tickFormatter={formatFunction}
                                           fontSize={12}
                                           tick={{fill: "#003468"}}
                                           minTickGap={20}
                                           width={80}
                                    />
                                    <Tooltip/>
                                    <defs>
                                        <linearGradient id="colorUsage" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#003468" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#003468" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <Area type="monotone" dataKey="usage" stroke="#003468" fillOpacity={1} fill="url(#colorUsage)"/>
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                        </>
                        }
                </Container>
        </>
    }
}

export default UsageHistory