import React from 'react';
import { Modal } from "react-bootstrap";
import MfaDeleteForm from "./MfaDeleteForm";

const modalTitle = "Multi-factor authentication removal"

class MfaDeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { modal, modalClose } = this.props;

        return (
            <>
                <Modal
                    className="main-modal"
                    onHide={modalClose}
                    show={modal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <>
                                <MfaDeleteForm updateModalScreen={this.updateModalScreen}
                                               {...this.props}/>
                            </>
                    </Modal.Body>
                </Modal>
            </>
        );
    };
}
export default MfaDeleteModal;