import React from 'react';
import {get} from '../../utils/request';
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap';
import BillingInformation from "../../component/BillingInformation/BillingInformation";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import SettingsNav from "../../component/SettingsNav/SettingsNav";

import {faSave} from "@fortawesome/free-solid-svg-icons";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                Message: '',
                IncreaseLimitRequest: false,
            },
            errorMessage: '',
            errors: {},
            lastSubmitTime: null,
            loading: true,
            loadingSuccess: false,
            showData: {
                email: '',
                paid: '',
            },
            successMessage: ''
        };
        this.loadData = this.loadData.bind(this);
        this.outsideFormSubmit = this.outsideFormSubmit.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({errors: {}});
            this.setState({loading: false});
            return
        }
        const data = res.data;
        const showData = {
            email: data.email,
            paid: data.paid,
        };
        delete data.email;
        delete data.paid;

        this.setState({data: data});
        this.setState({showData: showData});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    outsideFormSubmit() {
        this.setState({lastSubmitTime: new Date()});
    }

    render() {
        const { errorMessage, lastSubmitTime, loading, loadingSuccess, showData, successMessage } = this.state;

        return <Layout activeMenu="/settings" isLoggedIn={true}>
            <h1 className="main-heading">Settings</h1>
            <SettingsNav/>
                {loading &&
                <>
                    <Spinner animation="border" variant="primary"/> Loading data...
                    <br/><br/>
                </>
                }
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {loadingSuccess &&
                    <>
                        <BillingInformation lastSubmitTime={lastSubmitTime}/>
                    </>
                }
            <Container>
                <h2>Package</h2>
                <Form.Group as={Row} controlId="formPackage">
                    <Form.Label column sm="2">Package</Form.Label>
                    <Col sm="10">
                        <p>
                            {showData.paid ? "Space Premium" : "Space Trial"}</p>
                    </Col>
                </Form.Group>
            </Container>
            <div className="d-flex">
                <Button label="Save settings" faIcon={faSave} className="primary btn btn-right" onClick={this.outsideFormSubmit}/>
            </div>
        </Layout>;
    }
}

export default Settings