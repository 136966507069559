import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Link.scss";
import '../Button/Button.scss';

const Link = ({ className, faIcon, href, label }) => {

    let fontAwesomeIcon = <FontAwesomeIcon className="fa-icon" icon={faIcon}/>;

    if(!faIcon) {
        fontAwesomeIcon = "";
    }

    return (
        <a
            className={className}
            href={href}
        >
            {fontAwesomeIcon}
            {label}
        </a>
    );
}

export default Link;