import React from 'react';
import { get, post } from '../../utils/request';
import { Form, Row, Spinner } from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faSave} from "@fortawesome/free-solid-svg-icons";

class EditBucketVersioning extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            errorMessage: "",
            data: {},
            loading: true,
            loadingSuccess: false,
            location: location,
            name: this.props.match.params.name,
            selectedOption: "",
        };
        this.handleVersioningChange = this.handleVersioningChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/auth/bucket/" + this.state.name + "/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});

            return
        }
        const data = res.data;

        if (data.versioning === "") {
            data.versioning = "Unversioned";
        }

        this.setState({data: data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
        this.setState({selectedOption: this.state.data.versioning });
    }

    handleVersioningChange(event) {
        this.setState({
            selectedOption: event.target.value
        });
    }

    getLabel(status) {
        return  (this.state.data.versioning === status) ? status + " (current)" : status;
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});

        const bucketName = this.state.data.name;
        const versioningStatus = this.state.selectedOption;

        if (this.state.data.versioning !== versioningStatus) {
            const [res, err] = await post(this, "/auth/bucket/" + bucketName + "/versioning/" + versioningStatus + "/in/" + this.state.location);
                if (err) {
                    if (err.response && err.response.status) {
                        switch (err.response.status) {
                            case 400:
                                this.setState({errorMessage: "Bucket versioning status cannot be changed."});

                                return;
                            case 500:
                                this.setState({errorMessage: "Oops, something went wrong. Please try again later."});

                                return;
                        }
                    }
                    this.setState({errorMessage: "Error: " + err});

                    return;
                }
        }
        this.props.history.push("/buckets/" + bucketName + "/properties?location=" + this.state.location)
    }

    render() {
        const { data, errorMessage, loading, loadingSuccess, selectedOption } = this.state;

        return <Layout activeMenu="/buckets" isLoggedIn={true}>
                <h1 className="main-heading">Edit bucket versioning</h1>
                {loading &&
                    <>
                        <Spinner animation="border" variant="primary"/>Loading bucket versioning...
                        <br/><br/>
                    </>
                }
                {loadingSuccess &&
                    <>
                    <Container>
                    <Form onSubmit={e => this.handleSubmit(e)}>
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        <h2>Bucket versioning</h2>
                        <p className="ctr__info-text">When versioning is enabled, you can retrieve and restore any previous
                            version of the objects in the created bucket. Multiple versions count towards storage
                            usage.</p>
                        <Form.Group as={Row} controlId="formPlaintextUser" className="form-fieldnames">
                            <Form.Label column sm="6">Bucket versioning</Form.Label>
                        </Form.Group>
                        <div className="ctr__section-spacing">
                        {data.versioning === "Unversioned" &&
                            <Form.Check
                                label={this.getLabel("Unversioned")}
                                value="Unversioned"
                                id="Unversioned"
                                name="versioning"
                                type="radio"
                                checked={selectedOption === "Unversioned"}
                                onChange={this.handleVersioningChange}
                                className="mb-3"
                            />
                        }
                        {data.versioning !== "Unversioned" &&
                            <div>
                                <Form.Check
                                    label={this.getLabel("Suspended")}
                                    id="Suspended"
                                    value="Suspended"
                                    name="versioning"
                                    type="radio"
                                    checked={selectedOption === "Suspended"}
                                    onChange={this.handleVersioningChange}
                                />
                                <p className="ctr__info-text">This suspends the creation of new versions, however any existing
                                versions of the object will be preserved.</p>
                            </div>
                        }
                        <Form.Check
                            label={this.getLabel("Enabled")}
                            value="Enabled"
                            id="Enabled"
                            name="versioning"
                            type="radio"
                            checked={selectedOption === "Enabled"}
                            onChange={this.handleVersioningChange}
                            className="mb-3"
                        />
                        </div>
                    </Form>
                    </Container>
                    <div className="d-flex justify-space-end">
                        <Link className="lnk-btn primary-outline mr3" faIcon={faArrowCircleLeft} href={"/buckets/" + data.name + "/properties?location=" + this.state.location} label="Back"/>
                        <Button className="primary btn" faIcon={faSave} label="Save changes" onClick={this.handleSubmit}/>
                    </div>
                    </>
                }
        </Layout>;
    }
}

export default EditBucketVersioning