import React from 'react'
import {get} from '../../utils/request'
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap';
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import SettingsNav from "../../component/SettingsNav/SettingsNav";
import MfaAddModal from "../mfa/MfaAddModal";
import MfaDeleteModal from "../mfa/MfaDeleteModal";
import './settings.scss';

class SecuritySettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addModal: false,
            data: {},
            errorMessage: '',
            errors: {},
            loading: true,
            loadingSuccess: false,
            lastSubmitTime: null,
            successMessage: '',
            removeModal: false,
        };
        this.addModalOpen = this.addModalOpen.bind(this);
        this.loadData = this.loadData.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.removeModalOpen = this.removeModalOpen.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    addModalOpen() {
        if(!this.state.addModal) {
            this.setState({addModal: true});
        }
    }

    removeModalOpen() {
        if(!this.state.removeModal) {
            this.setState({removeModal: true});
        }
    }

    modalClose() {
        this.setState({
            addModal: false,
            removeModal: false,
            modalScreen: 0,
            phoneNumber: '',
        });
        window.location.reload();
    };

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({errors: {}});
            this.setState({loading: false});
            return
        }
        const data = res.data;

        this.setState({data: data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    render() {
        const { addModal, data, errorMessage, loading, loadingSuccess, removeModal, successMessage } = this.state;

        return <Layout activeMenu="/settings" isLoggedIn={true}>
            <h1 className="main-heading">Settings</h1>
            <SettingsNav/>
                {loading &&
                    <>
                        <Spinner animation="border" variant="primary"/> Loading data...
                        <br/><br/>
                    </>
                }
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {loadingSuccess &&
                    <>
                        <Container>
                        <h2>Multi-factor authentication</h2>
                        <p className="ctr__info-text">Multi-factor authentication adds an extra layer of security to your account by requiring you to provide a verification code sent via SMS when logging in once enabled.
                        </p>
                            <Form.Group as={Row} controlId="formMfaPhone" className="form-fieldnames">
                                <Form.Label column sm="2">Phone number</Form.Label>
                                <Col sm="10">
                                    <div className="d-flex security-settings-mfa__text">
                                        {data.mfaPhone && <p>{data.mfaPhone}</p>}
                                        {data.mfaPhone && <Button className="btn-lnk" label="Remove" onClick={this.removeModalOpen}/>}
                                        {!data.mfaPhone && <Button className="btn-lnk" label="Add" onClick={this.addModalOpen}/>}
                                    </div>
                                </Col>
                            </Form.Group>
                        <>
                            <MfaAddModal modal={addModal} modalClose={this.modalClose}/>
                        </>
                        <>
                            <MfaDeleteModal MfaPhone={data.mfaPhone} modal={removeModal} modalClose={this.modalClose}/>
                        </>
                        </Container>
                    </>
                }
        </Layout>;
    }
}

export default SecuritySettings