import React from 'react'
import Button from "../../component/Button/Button";
import Modal from "react-bootstrap/Modal";
import {faTrash, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";

class DeleteKey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            s3Key: props.s3Key,
            delete: props.delete,
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleShow() {
        this.setState({show: true});
    }

    handleClose() {
        this.setState({show: false});
    }

    render() {
        return <>
            <Button className="secondary-outline btn btn-md" faIcon={faTrash} label="Delete" onClick={this.handleShow}/>

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Permanently delete key</Modal.Title>
                </Modal.Header>
                <Modal.Body>Key: {this.state.s3Key}</Modal.Body>
                <Modal.Footer>
                    <Button className="cancel-outline btn" faIcon={faXmarkCircle} label="Cancel" onClick={this.handleClose}/>
                    <Button className="secondary btn" faIcon={faTrash} label="Delete" onClick={this.state.delete.bind(this, this.state.s3Key)}/>
                </Modal.Footer>
            </Modal>
        </>
    }
}

export default DeleteKey