import React from 'react'
import Alert from "../../component/Alert/Alert";
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import {get} from "../../utils/request";
import {loadStripe} from "@stripe/stripe-js";
import {Button, Spinner} from "react-bootstrap";

import {stripePromise} from "./stripePromise";

class PendingAuthenticationStripeInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            successMessage: "",
            loading: true,
            pending: false,
            data: {
                StripePending: false,
                StripeMethodId: "",
                StripeIntentSecret: "",
            },
            history: props.history,
        };
        this.loadData = this.loadData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/payment/pendingAuthentication");
        if (err) {
            this.setState({loaded: true});
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({data: res.data});
        this.setState({pending: res.data.StripePending === true});
        this.setState({loading: false});
    }

    async confirmPayment(stripe, elements, methodId, intentSecret) {
        try {
            const result = await stripe.confirmCardPayment(intentSecret, {
                payment_method: methodId,
            });
            if (result.error) {
                const errorMessage = result.error.code ? result.error.code : "Error: " + JSON.stringify(result.error)

                return [null, errorMessage]
            }
            return [result, ""]
        } catch (e) {
            return [null, "caught error: " + e]
        }
    }

    async handleSubmit(event) {
        event.preventDefault();

        const {stripe, elements} = this.props
        if (!stripe || !elements) { // todo - do we need elements here?
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }

        this.setState({errorMessage: ""});
        this.setState({successMessage: ""});
        this.setState({inProgress: true});

        const [result, confirmPaymentError] = await this.confirmPayment(
            stripe,
            elements,
            this.state.data.StripeMethodId,
            this.state.data.StripeIntentSecret
        )
        if (confirmPaymentError) {
            this.setState({errorMessage: confirmPaymentError});
            this.setState({inProgress: false});
            return;
        }



        // todo - send the success info to backend as well
        // const saveMethodError = await this.saveMethod(methodId)
        // if (saveMethodError) {
        //     this.setState({errorMessage: saveMethodError});
        //     this.setState({inProgress: false});
        //     return
        // }

        // this.props.history.push('/billing/addPaymentMethodDone');
        this.setState({successMessage: "all ok"});


    };

    render() {
        if (this.state.loading) {
            return <>
                <Spinner animation="border" variant="primary"/> Loading ...
            </>
        }

        if (!this.state.pending) {
            return <>
                <Alert variant="success">
                    No pending authentications!
                    <br/><br/><Button href="/billing">Go back to Billing</Button>
                </Alert>
            </>
        }

        return <>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
            <form onSubmit={this.handleSubmit}>
                <br/>
                <button disabled={false}>Authorize</button>
            </form>
        </>
    }
}

export default function PendingAuthenticationStripe(props) {
    return (
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
                {({stripe, elements}) => (
                    <PendingAuthenticationStripeInternal stripe={stripe} elements={elements} history={props.history}/>
                )}
            </ElementsConsumer>
        </Elements>
    );
}