import React from 'react';
import "./Alert.scss";

const Alert = ({ variant, children, className }) => {

    let classNames = "custom-alert custom-alert__" + variant + " " + className;

    return (
        <div
            className={classNames}
        >
            {children}
        </div>
    );
}

export default Alert;