import React from 'react'
import {Nav} from 'react-bootstrap'
import "./SideMenu.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBucket,
    faCog,
    faCreditCard,
    faHouseUser,
    faKey,
} from "@fortawesome/free-solid-svg-icons";

const SideMenu = ({activeMenu}) => {
    return (
        <section>
            <Nav activeKey={activeMenu}>
                <Nav.Link href="/"><FontAwesomeIcon className="icon" icon={faHouseUser}/><span>Home</span></Nav.Link>
                <Nav.Link href="/buckets"><FontAwesomeIcon className="icon" icon={faBucket} /><span>Buckets</span></Nav.Link>
                <Nav.Link href="/key"><FontAwesomeIcon className="icon" icon={faKey} /><span>Keys</span></Nav.Link>
                <Nav.Link href="/billing"><FontAwesomeIcon className="icon" icon={faCreditCard} /><span>Billing</span></Nav.Link>
                <Nav.Link href="/settings"><FontAwesomeIcon className="icon" icon={faCog} /><span>Settings</span></Nav.Link>
            </Nav>
        </section>
    );
}

export default SideMenu;