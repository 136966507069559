import React from 'react'
import {post, get} from '../../utils/request';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Link from "../../component/Link/Link";
import { Col, Form, Row } from "react-bootstrap";
import Layout from "../../component/Layout/Layout";
import MfaLoginModal from "../mfa/MfaLoginModal";
import mfaErrorHandler from "../mfa/MfaErrorHandler";
import queryString from 'query-string';
import './signIn.scss';

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                user: '',
                pass: '',
                code: '',
            },
            disableLoginFields: false, // This is to prevent password managers to autofill password during rerender after first password submit attempt (for example when mfa modal opens)
            errors: {},
            message: '',
            mfaErrMessage: '',
            mfaFailed: false,
            mfaResendLinkBool: false,
            modal: false,
            showMfaErrors: false,
            warning: '',
        };
        this.checkIfMfaValidationErrors = this.checkIfMfaValidationErrors.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCodeInput = this.handleChangeCodeInput.bind(this);
        this.handleMfaErrors = this.handleMfaErrors.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hasError = this.hasError.bind(this);
        this.showError = this.showError.bind(this);
        this.resendLink = this.resendLink.bind(this);
        this.resetCode = this.resetCode.bind(this);
    }

    handleMfaErrors(mfaRes) {
        const mfaFailed = mfaRes.mfaFailed;
        const mfaErrMessage = mfaRes.message;
        const mfaResendLinkBool = mfaRes.resendLinkBool;
        this.setState({mfaFailed: mfaFailed});
        this.setState({mfaErrMessage: mfaErrMessage});
        this.setState({mfaResendLinkBool: mfaResendLinkBool});
        this.setState({showMfaErrors: true});
        this.showModal();
    }

    handleChangeCodeInput(event) {
        const data = this.state.data;
        data.code = event.target.value.replace(/\D/g, '');
        this.setState({data: data});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            return error.message;
        }
    }

    checkIfMfaValidationErrors(data) {
        if (data.user && data.code && data.pass) {
            return true;
        }
        return false;
    }

    isCodeCorrect(code) {
        if (this.state.modal) {
            if (code.length !== 6) {
                this.setState({mfaErrMessage: "Please enter a valid 6-digit code"});
                this.setState({showMfaErrors: true});
                return false;
            }

        }
        this.clearAllErrors();
        return true
    }

    resetCode() {
        const data = this.state.data;
        data.code = '';
        this.setState({data: data});
    }

    resendLink() {
        this.clearAllErrors();
        this.resetCode();
        this.setState({modal: false});
        this.setState({mfaResendLinkBool: false});
        this.handleSubmit();
    }

    clearAllErrors() {
        this.setState({message: ""});
        this.setState({showMfaErrors: false});
        this.setState({mfaErrMessage: ""});
        this.setState({errors: {}});
    }

    showModal() {
        if(!this.state.modal) {
            this.setState({disableLoginFields: true});
            this.setState({showMfaErrors: false});
            this.setState({modal: true});
        }
    }

    modalClose = () => {
        this.setState({
            disableLoginFields: false,
            errors: {},
            mfaErrMessage: '',
            modal: false,
            showMfaErrors: false,
        });
        this.resetCode();
    };

    async handleSubmit(event) {
        if (event) { // TODO - remove if possible
            event.preventDefault();
        }
        if (!this.state.mfaResendLinkBool && !this.isCodeCorrect(this.state.data.code)) {

            return;
        }
        this.clearAllErrors();

        const [res, err] = await post(this, "/signIn", this.state.data);
        if (err) {
                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            if (this.checkIfMfaValidationErrors(this.state.data)) {
                                this.setState({mfaErrMessage: "Invalid code, please enter a valid code."});
                                this.showModal();
                            } else {
                                this.setState({message: "Failed to log in. Either email or password is not correct."});
                            }

                            return;
                        case 412:
                            switch (err.response.data.StatusText) {
                                case "waiting_confirm":
                                    this.setState({warning: "Please confirm your email before logging in."});
                                    return;
                                case "waiting_activation":
                                    this.setState({warning: "Your account is waiting in queue to be activated."});

                                    return;
                            }
                            if (err.response.data.StatusText.startsWith("mfa")) {
                                let mfaRes = mfaErrorHandler(err.response.data.StatusText);
                                this.handleMfaErrors(mfaRes);

                                return;
                            }
                            this.setState({warning: "account not active"});

                            return;
                        case 422:
                            const errors = err.response.data.ValidationErrors;
                            this.setState({errors: errors});
                            this.setState({message: "Not able to log in"});

                            return;
                    }

                }
                this.setState({message: "error: " + err});

                return;
            }
            this.setState({message: "Signed in"});

            let redirectTo = "/";

            const search = this.props.location.search;
            const values = queryString.parse(search)
            if (values.rt) {
                redirectTo = "/" + values.rt;
            }

            this.props.history.push(redirectTo);
    }

    render() {
        const { data, disableLoginFields, message, mfaErrMessage, mfaFailed, mfaResendLinkBool, modal, showMfaErrors, warning } = this.state;

        let signUpLink = `${process.env.REACT_APP_HOMEPAGE_URL}`;

        if (`${process.env.NODE_ENV}` !== "development") {
            signUpLink += "/sign-up"
        }

        return <Layout layoutClass="signin-ctr__bg " activeMenu="/signIn">
            <img src="../../../image/Storadera_BG.png" height="100%" alt="Storadera_Background"/>
            <div>
                <div>
                <h1>Sign in</h1>
                    {modal && <MfaLoginModal
                    loginCode={data.code}
                    mfaErrMessage={mfaErrMessage}
                    mfaFailed={mfaFailed}
                    modalClose={this.modalClose}
                    modal={modal}
                    onSubmit={this.handleSubmit}
                    onChange={this.handleChangeCodeInput}
                    resendLinkBool={mfaResendLinkBool}
                    resendLink={this.resendLink}
                    showMfaErrors={showMfaErrors}
                    value={data.code}

                />}
                    <Form onSubmit={this.handleSubmit}>
                    <Container variant="borderless">
                        {warning && <Alert variant="warning">{warning}</Alert>}
                        {message && <Alert variant="danger">{message}</Alert>}
                        <Form.Group as={Row} controlId="formPlaintextUser">
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="10">
                                <Form.Control autoFocus type="text" name="user" onChange={this.handleChange}
                                              isInvalid={this.hasError("User")} disabled={disableLoginFields}/>
                                <Form.Control.Feedback type="invalid">{this.showError("User")}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formBasicPassword">
                            <Form.Label column sm="2">Password</Form.Label>
                            <Col sm="10">
                                <Form.Control type="password" name="pass" onChange={this.handleChange} disabled={disableLoginFields}
                                              isInvalid={this.hasError("Pass")}/>
                                <Form.Control.Feedback type="invalid">{this.showError("Pass")}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                </Container>
                    <Button className="primary btn btn-xl mb4" label="Sign in" type={"submit"}/>
                    <div className="d-flex signin-ctr__btns">
                        <span><p>Don't have an account?</p><Link className="lnk" label="Sign up" href={signUpLink}/></span>
                        <Link className="lnk" label="Forgot password?" href="/password/forgot"/>
                    </div>
                    </Form>
                </div>
            </div>
        </Layout>;
    }
}

export default SignIn