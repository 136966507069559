import React from 'react';
import {CardCvcElement, CardExpiryElement, CardNumberElement} from '@stripe/react-stripe-js';
import './Styles.css'
import {Col, Row} from "react-bootstrap";
import "./billing.scss";

const CARD_ELEMENT_OPTIONS = {
    theme: 'none',
    style: {
        base: {
            color: "#003468",
            fontFamily: '"Rubik","Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "0.9rem",
            "::placeholder": {
                color: "#aab7c4",
            },
            borderRadius: "5px",
            lineHeight: '1.5',
        },
        invalid: {
            color: "#A63140",
            iconColor: "#A63140",
        },
    },
};

function AddStripeForm() {
    return (
        <>
            <div className="ctr-add-stripe">
                <Row className="logos">
                    <Col><img src="/image/mc_vrt_pos.svg" alt="mastercard logo" height={40} align="center"/></Col>
                    <Col><img src="/image/Visa_Brandmark_Blue_RGB_2021.svg" alt="visa logo" height={40} align="center"/></Col>
                    <Col><img src="/image/amex_SMALLscale_RGB_DIGITAL_80x80.svg" alt="amex logo" height={40} align="center"/></Col>
                    <Col><img src="/image/powered_by_stripe.svg" alt="stripe logo" height={35}
                                        align="center"/></Col>
                </Row>
                <Row>
                    <Col>
                        <label>Card number*</label>
                        <CardNumberElement options={CARD_ELEMENT_OPTIONS}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Expiry date*</label>
                        <CardExpiryElement options={CARD_ELEMENT_OPTIONS}/>
                    </Col>
                    <Col>
                        <label>CVC / CVV*</label>
                        <CardCvcElement options={CARD_ELEMENT_OPTIONS}/>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default AddStripeForm;