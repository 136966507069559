import React from 'react'
import Layout from "../../component/Layout/Layout";
import Steps from "../../component/Steps/Steps";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./spacePremium.scss";

const SpacePremiumForm = ({step, children}) => {

    return <>
        <Layout activeMenu="/" isLoggedIn={true} noMenu layoutClass="gradient-bg">
            <div className="mx-auto space-premium">
                <a href="/"><div className="text-right"><FontAwesomeIcon icon={faTimes} className={"close-icon"} size="2x"/></div></a>
            <Steps step={step}/>
                <div>{children}</div>
            </div>
        </Layout>
    </>
}

export default SpacePremiumForm;