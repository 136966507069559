import React, {Component} from "react";
import {Container} from "react-bootstrap";
import SpacePremiumForm from "./SpacePremiumForm";
import Link from "../../component/Link/Link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import "./spacePremium.scss";
import {get} from "../../utils/request";

export class SuccessStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        };
        this.loadData = this.loadData.bind(this);
    }
    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            return;
        }
        this.setState({data: res.data});
    }

    render() {
        return (
            <>
            <SpacePremiumForm step="4">
                <Container className="justify-content-center space-premium-success-ctr">
                    <FontAwesomeIcon icon={faCheckCircle} size="5x" className={"space-premium-check-icon-success"}/>
                    <h1>Success!</h1>
                    <p>You can now start using your Space Premium</p>
                    <Link className="lnk-btn primary btn-lg" label="Go to home" href="/"/>
                </Container>
            </SpacePremiumForm>
            </>
        )
    }
}
export default SuccessStep