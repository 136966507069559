import React from 'react'
import {post} from '../../utils/request'
import queryString from "query-string";
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Layout from "../../component/Layout/Layout";
import { Col, Form, Row } from 'react-bootstrap';
import './password.scss';

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hash: "",
            pass: "",
            errorMessage: "",
            errors: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hasError = this.hasError.bind(this);
        this.showError = this.showError.bind(this);
    }

    async componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if (!values.h) {
            this.props.history.push('/password/expired');
            return;
        }

        const data = {hash: values.h};
        const [res, err] = await post(this, "/passwordReset/verify", data);
        if (err) {
            switch (err.response.status) {
                case 404:
                case 422:
                    this.props.history.push('/password/expired');
                    return;
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({hash: values.h});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});

        const data = {
            hash: this.state.hash,
            pass: this.state.pass,
        };

        const [res, err] = await post(this, "/passwordReset/do", data);
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        this.props.history.push('/password/expired');
                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors;

                        this.setState({errors: errors});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.props.history.push('/password/done');
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error)
            return error.message;
        }
        return;
    }

    errorTranslator(error) {
        if (error.key === "length") {
            error.message = "Password must be at least 10 characters long";
        }
    }

    render() {
        const { errorMessage } = this.state;

        return <Layout activeMenu="/signIn">
            <div className="password-ctr">
                <h1>Reset your password</h1>
                <Form onSubmit={e => this.handleSubmit(e)}>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form.Group as={Row} controlId="formPassword">
                        <Form.Label column sm="2">Password</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="password"
                                name="pass"
                                isInvalid={this.hasError("pass")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback type="invalid">{this.showError("pass")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Button className="btn primary btn-xl" label="Set new password" type="submit"/>
                </Form>
            </div>
        </Layout>;
    }
}

export default PasswordReset