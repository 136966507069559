import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "../../component/Button/Button";
import './mfa.scss';

export class MfaAddConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { modalClose, phoneNumber } = this.props;

        return (
            <>
                <div className="confirmation-ctr">
                    <Container className="text-center">
                        <FontAwesomeIcon icon={faCheckCircle} size="4x" className={"space-premium-check-icon-success"}/>
                        <h2>Successfully enabled!</h2>
                    </Container>
                    <Container className="ml-auto">
                        <p>Phone number: <b>+{phoneNumber}</b></p>
                        <p>Authentication codes will be texted to this number for logging in.</p>
                        <Row className="d-flex justify-content-end">
                            <Button className="primary btn btn-md" label="Done" onClick={modalClose} type={"button"}/>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}
export default MfaAddConfirmation