import React from 'react'

import Layout from "../../component/Layout/Layout";
import PendingAuthenticationStripe from "./PendingAuthenticationStripe";

class PendingAuthentication extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <Layout activeMenu="/billing" isLoggedIn={true}>
            <h1>Pending authentication</h1>
            <PendingAuthenticationStripe history={this.props.history}/>
        </Layout>
    }
}

export default PendingAuthentication