import React from 'react';
import {Col ,Form,Row } from "react-bootstrap";
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";

class MfaLoginVerifyCodeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

      render() {
        const { mfaErrMessage, modalClose, onChange, onSubmit, resendLink, resendLinkBool, showMfaErrors, value } = this.props;

        return <>
            {mfaErrMessage && <Alert variant="danger-text">{mfaErrMessage}</Alert>}
            <Form className="form-fieldnames verify-code-form" onSubmit={onSubmit}>
                <p>Enter the 6-digit code we sent to your phone.</p>
                <Form.Group as={Row} controlId="formCode">
                    <Form.Label column sm="4">Code</Form.Label>
                    <Col sm="8">
                        <Form.Control
                            autoFocus
                            type="text"
                            name="code"
                            value={value}
                            isInvalid={showMfaErrors}
                            onChange={onChange}
                        />
                    </Col>
                </Form.Group>
                {resendLinkBool && <Row><Button className="btn-lnk mx-auto mb-3" label="Resend code" onClick={resendLink}/></Row>
                }
                <div className="d-flex justify-content-end">
                    <Button className="cancel-outline btn mr3 btn-md" label="Cancel" onClick={modalClose} type={"button"}/>
                    <Button className="primary btn btn-md" label="Verify" type={"submit"}/>
                </div>
            </Form>
        </>
    }
}

export default MfaLoginVerifyCodeForm;