import React from 'react'
import Alert from 'react-bootstrap/Alert';
import {get, getHost, post} from '../../utils/request'
import Button from "../../component/Button/Button";
import Layout from "../../component/Layout/Layout";
import BillingNav from "../../component/BillingNav/BillingNav";
import {Spinner} from "react-bootstrap";
import "../../component/Table/Table.scss"
import {faTags} from "@fortawesome/free-solid-svg-icons";
import SkipIfManagedByPartner from "../../component/SkipIfManagedByPartner/SkipIfManagedByPartner";
class VoucherList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vouchers: [],
            errorMessage: '',
            loading: true,
            loadingSuccess: false,

        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/voucher",);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({vouchers: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    async componentDidMount() {
        await this.loadData({});
    }

    render() {
        return <Layout activeMenu="/billing" isLoggedIn={true}>
            <h1>Billing</h1>
            <BillingNav/>
            <SkipIfManagedByPartner>
                <h2>Vouchers ({this.state.vouchers ? this.state.vouchers.length : 0})</h2>
                {this.state.loading &&
                    <>
                        <Spinner animation="border" variant="primary"/> Loading vouchers...
                        <br/><br/>
                    </>
                }
                {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                {this.state.loadingSuccess &&
                    <>
                        <div className="d-flex">
                            <Button className="primary btn btn-right btn-margin" faIcon={faTags} label="Add new voucher" onClick={() => this.props.history.push('/billing/vouchers/new')}/>
                        </div>
                        {this.state.vouchers.length > 0 &&
                            <table className="table-ctr">
                                <thead>
                                <tr>
                                    <th>Voucher Code</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Discount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.vouchers && this.state.vouchers.map((value, index) => {
                                    return <tr key={index}>
                                        <td data-label="Voucher Code">{value.Code}</td>
                                        <td data-label="Start Time">{value.StartTime}</td>
                                        <td data-label="End Time">{value.EndTime}</td>
                                        <td data-label="Discount">{value.DiscountPerc}%</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        }
                    </>
                }
            </SkipIfManagedByPartner>
        </Layout>;
    }
}

export default VoucherList
