import React, {Component} from "react";
import BillingInformation from "../../component/BillingInformation/BillingInformation";
import Button from "../../component/Button/Button";
import Link from "../../component/Link/Link";
import SpacePremiumForm from "./SpacePremiumForm";
import {Container} from "react-bootstrap";
import "./spacePremium.scss";
import {get} from "../../utils/request";


export class SuccessStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            lastSubmitTime: null,
            hrefLink: '/SpacePremium/3',
        };
        this.loadData = this.loadData.bind(this);
        this.outsideFormSubmit = this.outsideFormSubmit.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            return;
        }
        this.setState({data: res.data});
    }

    outsideFormSubmit() {
        this.setState({lastSubmitTime: new Date()});
    }

    render() {
        return (
            <>
            <SpacePremiumForm step="2">
                <Container className="justify-content-center space-premium-forms-ctr">
                    <BillingInformation
                        history={this.props.history}
                        lastSubmitTime={this.state.lastSubmitTime}
                        hrefLink={this.state.hrefLink}
                        alertClass={"alert-size"}
                        variant={"borderless"}
                    />
                </Container>
                <div className="d-flex justify-content-between">
                    <Link className="cancel-outline lnk-btn btn-lg" label="Back" href="/SpacePremium"/>
                    <Button className="primary btn btn-lg" label="Continue" onClick={this.outsideFormSubmit}/>
                </div>
            </SpacePremiumForm>
            </>
        )
    }
}
export default SuccessStep