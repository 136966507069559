import {Col, Container, Row} from "react-bootstrap";
import React from "react";

const BillingSecurityText = () =>
{
    return (
        <Container>
            <Row className="billing-text">
                <Col md="auto">
                    <img src="/image/padlock-icon.svg" alt="padlock-icon" height={30}/>
                </Col>
                <Col>
                    <p>Your billing information will be encrypted and is 100% safe with our payment processing
                        partner Stripe.
                        Your payment information is not stored in our servers.</p>
                </Col>
            </Row>
        </Container>
    )
}
export default BillingSecurityText;

