import React from 'react';
import { Modal } from "react-bootstrap";
import MfaAddConfirmation from "./MfaAddConfirmation";
import MfaAddPhoneNumberForm from "./MfaAddPhoneNumberForm";
import MfaAddVerifyCodeForm from "./MfaAddVerifyCodeForm";

const modalTitle = "Multi-factor authentication setup"

class MfaAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalScreen: 0,
            phoneNumber: '',
        };
        this.updateModalScreen = this.updateModalScreen.bind(this);
        this.passPhoneNr = this.passPhoneNr.bind(this);
    }

    updateModalScreen(newValue) {
        this.setState({modalScreen: newValue})
    }

    passPhoneNr(newValue) {
        this.setState({phoneNumber: newValue})
    }

    render() {
        const { modalScreen, phoneNumber } = this.state;
        const { modal, modalClose } = this.props;

        return (
            <>
                <Modal
                    animation={false}
                    className="main-modal"
                    onHide={modalClose}
                    show={modal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalScreen === 0 && (
                            <>
                                <MfaAddPhoneNumberForm
                                    modalClose={modalClose}
                                    passPhoneNr={this.passPhoneNr}
                                    phoneNumber={phoneNumber}
                                    updateModalScreen={this.updateModalScreen}
                                />
                            </>
                            )}
                        {modalScreen === 1 && (
                            <>
                                <MfaAddVerifyCodeForm
                                    modalClose={modalClose}
                                    phoneNumber={phoneNumber}
                                    updateModalScreen={this.updateModalScreen}
                                />
                            </>
                        )}
                        {modalScreen === 2 && (
                            <>
                                <MfaAddConfirmation
                                    modalClose={modalClose}
                                    phoneNumber={phoneNumber}
                                    updateModalScreen={this.updateModalScreen}
                                />
                            </>
                        )}
                    </Modal.Body>
                </Modal>
            </>
        );
    };
}
export default MfaAddModal;