import React from 'react';
import { get } from '../../utils/request';
import {Col, Row, Spinner} from 'react-bootstrap';
import BucketsNav from "../../component/BucketsNav/BucketsNav";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";
import './buckets.scss';

class BucketProperties extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            data: {},
            loading: true,
            loadingSuccess: false,
            location: location,
            name: this.props.match.params.name,
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/auth/bucket/" + this.state.name + "/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({data: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    render() {
        const { data, loading, loadingSuccess } = this.state;

        return <Layout activeMenu="/buckets" isLoggedIn={true}>
                <h1 className="main-heading">Bucket {this.state.name}</h1>
                {loading &&
                        <>
                            <Spinner animation="border" variant="primary"/>Loading bucket properties...
                            <br/><br/>
                        </>
                }
                {loadingSuccess &&
                    <>
                        <BucketsNav name={this.state.name} location={this.state.location}/>
                        <Container>
                        <h2>Bucket name</h2>
                         <Row>
                             <Col sm="2">
                                 <label>Bucket name</label>
                             </Col>
                             <Col sm="10">
                                 <p>{data.name}</p>
                             </Col>
                        </Row>
                        <h2>Bucket versioning</h2>
                        <p className="ctr__info-text">When versioning is enabled, you can retrieve and restore any previous
                            version of the objects in the created bucket. Multiple versions count towards storage
                            usage.</p>
                        <Row>
                            <Col sm="2">
                                <label>Bucket versioning</label>
                            </Col>
                            <Col sm="10">
                                <div className="d-flex edit-bucket-properties__text">
                                <p>{data.versioning ? data.versioning : "Unversioned"}</p>
                                {!data.objectLock &&
                                    <Link className="lnk ml3" label="Edit versioning"
                                       href={"/buckets/" + data.name + "/properties/versioning/edit?location=" + this.state.location}/>
                                }
                                </div>
                            </Col>
                        </Row>
                        <h2>Object lock</h2>
                        <p className="ctr__info-text">Enabling object lock will prevent objects from being deleted or overwritten for a fixed amount of time and will enable versioning.
                            Object lock status cannot be altered after bucket creation.</p>
                        <Row>
                            <Col sm="2">
                                <label>Object lock</label>
                            </Col>
                            <Col sm="10">
                                <p>{data.objectLock ? "Enabled" : "Disabled"}</p>
                            </Col>
                        </Row>
                    </Container>
                    <div className="d-flex">
                        <Link className="primary btn btn-right btn-md" faIcon={faArrowCircleLeft} href={"/buckets"} label="Back"/>
                    </div>
                    </>
                }
        </Layout>;
    }
}

export default BucketProperties