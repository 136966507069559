import React from 'react';
import {GetSettings} from "../GetSettings";
import {Spinner} from "react-bootstrap";
import './SkipIfManagedByPartner.scss';

class SkipIfManagedByPartner  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: null,
        }
    }
    async componentDidMount() {
        this.setState({settings: await GetSettings()});
    }

    render() {
        if (!this.state.settings) {
            return <>
                <Spinner animation="border" variant="primary"/> Loading...
                <br/><br/>
                </>
        }

        if (this.state.settings.isManagedByPartner) {
            return <i className="managed-partner-text">This account is managed by: <b>{this.state.settings.partnerName}</b></i>
        }

        return this.props.children;
    }
}

export default SkipIfManagedByPartner;