import React from 'react'
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import './password.scss';

class PasswordExpired extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Layout activeMenu="/signIn">
            <div className="password-ctr">
                    <h1>Password reset link is not working</h1>
                    <p>Sorry, your password reset link is not valid. Most likely because the link has expired or was already used.</p>
                    <Link className="primary lnk-btn btn-xl" href="/password/forgot" label="Request a new link"/>
            </div>
        </Layout>;
    }
}

export default PasswordExpired