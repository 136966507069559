import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./Tabs.scss";

class Tab extends Component {
    static propTypes = {
        exactTo: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    };

    render() {
        const {
            props: {
                exactTo,
                faIcon,
                label,
            },
        } = this;

        return (
            <NavLink
                className="nav-link"
                exact to={exactTo}
                activeClassName="active nav-link"
            >
                <FontAwesomeIcon className="fa-icon" icon={faIcon}/>
                {label}
            </NavLink>
    );
    }
}

export default Tab;