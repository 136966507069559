import React from 'react'
import { post } from "../../utils/request";
import { Col, Form, Row } from 'react-bootstrap';
import mfaErrorHandler from "./MfaErrorHandler";
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";

class MfaAddVerifyCodeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                Code: '',
            },
            errorMessage: '',
            errors: {},
            formSuccess: false,
            isMfaError: false,
            resendLinkBool: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleMfaErrors = this.handleMfaErrors.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hasError = this.hasError.bind(this);
        this.showError = this.showError.bind(this);
        this.resendLink = this.resendLink.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    handleMfaErrors(mfaRes) {
        const errorMessage = mfaRes.message;
        const resendLinkBool = mfaRes.resendLinkBool;
        this.setState({errorMessage: errorMessage});
        this.setState({resendLinkBool: resendLinkBool});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            this.setState({isMfaError: true});
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return;
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            error.message = "Please enter a valid 6-digit " + field.toLowerCase();
        }
    }

    clearAllErrors() {
        this.setState({isMfaError: false});
        this.setState({message: ""});
        this.setState({errorMessage: ""});
        this.setState({errors: ""});
    }

    isCodeCorrect(code) {
        if (code.length !== 6) {
            this.setState({errorMessage: "Please enter a valid 6-digit code"});
            this.setState({isMfaError: true});
            return false;
        }
        this.clearAllErrors();
        return true;
    }

    resetCode() {
        const data = this.state.data;
        data.Code = '';
        this.setState({data: data});
    }

    resendLink() {
        this.setState({resendLinkBool: false});
        this.clearAllErrors();
        this.resetCode();
        this.props.updateModalScreen(0)
    }

    async handleSubmit(event) {
        if (event) { // TODO - remove if possible
            event.preventDefault();
        }

        if (!this.props.resendLinkBool && !this.isCodeCorrect(this.state.data.Code)) {
            return;
        }
        this.clearAllErrors();
        const [res, err] = await post(this, "/auth/mfa/phone/verify", this.state.data);
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 412:
                        let mfaRes = mfaErrorHandler(err.response.data.StatusText);
                        this.handleMfaErrors(mfaRes);
                        this.setState({isMfaError: true});
                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors;
                        this.setState({isMfaError: false});
                        this.setState({errors: errors});
                        return;
                    case 400:
                        this.setState({errorMessage: "Please enter a valid code"});
                        this.setState({errors: ""});
                        return;
                    case 500:
                        this.setState({errorMessage: "Something went wrong, please try again"});
                        return;
                }
            }
            this.setState({errorMessage: "Error: " + err});
            return;
        }

        this.setState({errors: ""});

        this.props.updateModalScreen(2);

        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    render() {
        const { errorMessage, resendLinkBool, data, isMfaError } = this.state;
        const { phoneNumber, modalClose  } = this.props;

        return <>
            {errorMessage && <Alert variant="danger-text">{errorMessage}</Alert>}
            <Form className="form-fieldnames verify-code-form" onSubmit={e => this.handleSubmit(e)}>
                <p>Enter the 6-digit code we sent to <b>+{phoneNumber}</b></p>
                <Form.Group as={Row} controlId="formCode">
                    <Form.Label column sm="4">Code</Form.Label>
                    <Col sm="8">
                        <Form.Control
                            autoFocus
                            name="Code"
                            isInvalid={isMfaError}
                            onChange={this.handleChange}
                            type="text"
                            value={data.Code}
                        />
                        <Form.Control.Feedback
                            type="invalid">{this.showError("Code")}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                {resendLinkBool && <Row><button className="btn-style-link mx-auto mb-3" onClick={this.resendLink}>Check phone number and resend code</button></Row>
                }
                <div className="d-flex justify-content-end">
                    <Button className="cancel-outline btn btn-md mr3" label="Cancel" onClick={modalClose} type={"button"}/>
                    <Button className="primary btn btn-md" label="Verify" type={"submit"}/>
                </div>
            </Form>
        </>
    }
}

export default MfaAddVerifyCodeForm;