import React from 'react';
import AddStripe from "./AddStripe";
import BillingSecurityText from "../../component/BillingSecurityText/BillingSecurityText";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import {faArrowCircleLeft, faCreditCard} from "@fortawesome/free-solid-svg-icons";
import Link from "../../component/Link/Link";

class AddPaymentMethod extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lastSubmitTime: null,
            hrefLink: '/billing/addPaymentMethodDone',
        };
        this.outsideFormSubmit = this.outsideFormSubmit.bind(this);
        this.propagateButtonEnabled = this.propagateButtonEnabled.bind(this);
        this.redirectAfterSubmit = this.redirectAfterSubmit.bind(this);
    }

    outsideFormSubmit() {
        this.setState({lastSubmitTime: new Date()});
    }

    propagateButtonEnabled(enabled) {
        if(this.state.buttonEnabled !== enabled) {
            this.setState({buttonEnabled: enabled});
        }
    }

    redirectAfterSubmit() {
        if(this.state.hrefLink) {
            this.props.history.push(this.state.hrefLink);
        }
    }

    render() {
        const saveButtonText = !this.state.buttonEnabled  ? "Saving" : "Save card";

        return <Layout activeMenu="/billing" isLoggedIn={true}>
            <div>
            <h1>Add payment method</h1>
            <Container>
                <AddStripe
                    history={this.props.history}
                    lastSubmitTime={this.state.lastSubmitTime}
                    hrefLink={this.state.hrefLink}
                    propagateButtonEnabled={this.propagateButtonEnabled}
                    redirectAfterSubmit={this.redirectAfterSubmit}
                />
            </Container>
            <BillingSecurityText/>
            <div className="d-flex justify-content-end">
                <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft} href={"/billing"} label="Back"/>
                <Button className="primary btn"
                        faIcon={faCreditCard}
                        label={saveButtonText}
                        onClick={this.outsideFormSubmit}
                        disabled={!this.state.buttonEnabled}
                />
            </div>
            </div>
        </Layout>
    }
}

export default AddPaymentMethod