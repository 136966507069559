import React from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import {post} from '../../utils/request'
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Layout from "../../component/Layout/Layout";
import Container from "../../component/Container/Container";

class VoucherNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                Code: '',
            },
            successMessage: '',
            errorMessage: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    errorTranslator(error) {
        let errorMessage = "";
        switch (error) {
            case "voucher_not_active_yet":
                errorMessage = "This voucher is not yet active";
                break;
            case "voucher_expired":
                errorMessage = "This voucher is expired and cannot be activated anymore";
                break;
            case "voucher_activation_full":
                errorMessage = "This voucher has reached its activation limit";
                break;
            case "voucher_not_premium":
                errorMessage = "You need Storadera Premium to activate this voucher";
                break;
            case "voucher_not_allowed":
                errorMessage = "Sorry, this voucher cannot be used on this account";
                break;
            case "voucher_already_activated":
                errorMessage = "This voucher has already been activated on your account";
                break;
            case "voucher_overlapping_vouchers":
                errorMessage = "The voucher you are trying to activate is overlapping with another active voucher on your account";
                break;
        }
        this.setState({errorMessage: errorMessage});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const data = this.state.data;

        data[name] = value;

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});

        const [res, err] = await post(this, "/auth/voucher/activate", this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Error: " + err.response.message});
                        return;
                    case 404:
                        this.setState({errorMessage: "Voucher not found, re-check the code"});
                        return;
                    case 412:
                        this.errorTranslator(err.response.data.StatusText)
                        return;
                    case 422:
                        this.setState({errorMessage: "Uh-oh! The voucher code needs to be at least 8 characters long"});
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                }
            }
            this.setState({errorMessage: "Uh-oh! " + err});
            return;
        }
        this.setState({successMessage: "Voucher activated!"});
    }

    render() {
        return <Layout activeMenu="/billing" isLoggedIn={true}>
            <h1>Activate a new voucher</h1>
            {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            <Container>
                <h2>Activate a new voucher</h2>
                <Form className="form-fieldnames form-voucher" onSubmit={e => this.handleSubmit(e)}>
                    <Form.Group as={Row} controlId="formPlaintextCode">
                        <Form.Label column sm="1">Code</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="Code" defaultValue={this.state.data.Code}
                                          onChange={this.handleChange}/>
                        </Col>
                        <Col>
                            <Button column sm="2" type="submit" className="btn primary btn-md mr3" label="Activate"/>
                            <Button column sm="2" className="btn primary-outline btn-md" label="Back"
                                    onClick={() => this.props.history.push('/billing/vouchers')}/>
                        </Col>
                    </Form.Group>
                </Form>
            </Container>
        </Layout>
    }
}

export default VoucherNew