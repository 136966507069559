import React from 'react'
import Link from "../../component/Link/Link";
import Layout from "../../component/Layout/Layout";
import Alert from "../../component/Alert/Alert";

class AddPaymentMethodDone extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <Layout activeMenu="/billing" isLoggedIn={true}>
            <h1>Add payment method successful</h1>
            <Alert variant="success">
                Successfully added a payment method.
            </Alert>
            <Link className="lnk" href="/billing" label="Go back to payment method list"/>
        </Layout>
    }
}

export default AddPaymentMethodDone