import React from 'react';
import { post } from "../../utils/request";
import { Col, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import 'react-phone-input-2/lib/style.css';

class MfaAddPhoneNumberForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                Phone: this.props.phoneNumber,
            },
            errorMessage: "",
            errors: {},
            mfaFailed: false,
            phone: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hasError = this.hasError.bind(this);
        this.showError = this.showError.bind(this);
    }

    handlePhoneInputChange(newValue) {
        const data = this.state.data;
        data.Phone = newValue;
        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return;
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            error.message = "Please enter a " + field.toLowerCase() + "number at least 8 characters long";
        } else if (error.key === "numeric") {
            error.message = "Please enter a valid " + field.toLowerCase() + " number";
        }
    }

    async handleSubmit(event) {
        if (event) { // TODO - remove if possible
            event.preventDefault();
        }
        this.setState({errorMessage: ""});
        const [res, err] = await post(this, "/auth/mfa/phone/verifyStart", this.state.data);
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 412:
                        this.setState({errorMessage: "The phone number has been entered incorrectly too many times. Please try again later."});
                        this.setState({mfaFailed: true});
                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors;
                        this.setState({errors: errors});
                        return;
                    case 400:
                        this.setState({errorMessage: "Please enter a valid phone number"});
                        return;
                    case 500:
                        this.setState({errorMessage: "Something went wrong, please try again"});
                        return;
                }
            }
            this.setState({errorMessage: "Error: " + err});
            return;
        }
        this.setState({errors: ""});

        window.scrollTo({top: 0, behavior: 'smooth'});

        this.props.updateModalScreen(1);
        this.props.passPhoneNr(this.state.data.Phone);
    }

    render() {
        const { errorMessage, phone, mfaFailed } = this.state;
        const { modalClose, phoneNumber  } = this.props;

        return <>
            {errorMessage && <Alert variant="danger-text">{errorMessage}</Alert>}
            {!mfaFailed &&
                <Form className="form-fieldnames add-phone-form" onSubmit={this.handleSubmit}>
                    <p>We’ll text a code to this phone number whenever you sign in to your account.</p>
                    <Form.Group as={Row} controlId="formPhone">
                        <Form.Label column sm="4">Phone number</Form.Label>
                        <Col sm="8">
                            <PhoneInput
                                inputProps={{
                                    autoFocus: true
                                }}
                                country={'ee'}
                                name="Phone"
                                isValid={() => {
                                    if (this.hasError("Phone")) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }}
                                onChange={this.handlePhoneInputChange.bind(this)}
                                value={phoneNumber ? phoneNumber : phone}
                            />
                            {this.hasError("Phone") &&
                                <div
                                    className="invalid-feedback block-display form-validation-error">{this.showError("Phone")}</div>
                            }
                        </Col>
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <Button className="cancel-outline btn mr3 btn-md" label="Cancel" onClick={modalClose} type={"button"}/>
                        <Button className="primary btn btn-md" label="Add" type={"submit"}/>
                    </div>
                </Form>
            }
            {mfaFailed &&
                <div className="d-flex justify-content-end">
                    <Button className="cancel-outline btn btn-md" label="Close" onClick={modalClose} type={"button"}/>
                </div>
            }
            </>
        }
}

export default MfaAddPhoneNumberForm;