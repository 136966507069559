import React from 'react';
import {get, post} from "../../utils/request";
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Layout from "../../component/Layout/Layout";
import {Col, Row, Spinner} from "react-bootstrap";
import Container from "../../component/Container/Container";
import {faArrowCircleLeft, faCheckCircle, faKey} from "@fortawesome/free-solid-svg-icons";
import './key.scss';

class NewKey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            created: false,
            show: false,
            reloadData: props.reloadData,
            loading: true,
            loadingSuccess: false
        };

        this.loadData = this.loadData.bind(this);
        this.create = this.create.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

//loadData and ComponentDidMount are only to force to login screen if not yet logged in
    async loadData() {
        const [res, err] = await get(this, "/auth/overview");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({data: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    componentDidMount() {
        this.loadData();
    }

    async create() {
        const [res, err] = await post(this, "/auth/s3Key/create", {});
        if (err) {
            return;
        }
        this.setState({data: res.data});
        this.setState({created: true});
    }

    handleShow() {
        this.setState({show: true});
    }

    handleClose() {
        this.setState({data: {}});
        this.setState({created: false});
        this.setState({show: false});
// this.state.reloadData();
    }

    render() {
        const homepageUrl = `${process.env.REACT_APP_HOMEPAGE_URL}`;

        return <Layout activeMenu="/key" isLoggedIn={true}>
            {this.state.loading &&
            <>
                <Spinner animation="border" variant="primary"/> Loading...
                <br/><br/>
            </>
            }
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            {this.state.loadingSuccess &&
            <>
                {!this.state.created &&
                    <>
                    <h1>Create new key</h1>
                    <Container>
                        <h2 className="hidden-heading">Create new key</h2>
                        <p>When you create a new key you will be shown the Secret Access Key only once. Make sure to write it down.</p>
                        <div className="key-btns d-flex justify-content-end">
                            <Button className="btn-outline-secondary btn btn-md mr3" faIcon={faArrowCircleLeft} label="Back" onClick={() => this.props.history.push('/key')}/>
                            <Button className="btn primary btn-md" faIcon={faKey} label="Generate new key" onClick={this.create}/>
                        </div>
                    </Container>
                    </>
                }
                {this.state.created &&
                    <>
                    <h1>Create new key</h1>
                    <Container>
                        <h2 className="hidden-heading">Create new key</h2>
                        <h3 className="key-created__msg">New key created!</h3>
                        <hr className="key-created__line"></hr>
                        <p>Please copy secret access key to safe place. It will be shown only once!
                            <br/>Host is shown here only for convenience, no need to save it.</p>
                        <Row>
                            <Col sm="2">
                                <label>Access Key ID</label>
                            </Col>
                            <Col sm="10">
                                <p>{this.state.data.accessKeyId}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="2">
                                <label>Secret Access Key</label>
                            </Col>
                            <Col sm="10">
                                <p>{this.state.data.secretAccessKey}</p>
                            </Col>
                        </Row>
                        <div className="key-btns d-flex justify-content-end">
                            <Button className="primary btn" faIcon={faCheckCircle} label="Ok, I have copied the Access Key and the Secret Access Key" onClick={() => this.props.history.push('/key')}/>
                        </div>
                    </Container>

                    <Alert variant="info">
                        <b>For technical configurations, please consult our documentation:</b> <br/>
                        <a href={homepageUrl+'/integrations'} target="_blank">Integration manuals</a> <br/>
                        <a href={homepageUrl+'/docs/regions'} target="_blank">Regions</a>
                    </Alert>
                </>}
            </>}
        </Layout>;
    }
}

export default NewKey