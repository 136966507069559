import React from 'react'
import {faPenToSquare, faGear} from '@fortawesome/free-solid-svg-icons'
import Tabs from "../Tabs/Tabs";

function BucketsNav({name, location}) {
    return (
        <Tabs>
            <div faIcon={faPenToSquare} exactTo={"/buckets/" + name + "/properties?location=" + location} label="Properties">
            </div>
            <div faIcon={faGear} exactTo={"/buckets/" + name + "/management?location=" + location} label="Management">
            </div>
        </Tabs>
    );
}

export default BucketsNav

