import React, {Component} from "react";
import {get} from "../../utils/request";
import Link from "../../component/Link/Link";
import SpacePremiumForm from "./SpacePremiumForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Container} from "react-bootstrap";
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import "./spacePremium.scss";


export class LandingStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            return;
        }

        this.setState({data: res.data});

        if(this.state.data.paid) {
            this.props.history.push('/')
        }
    }

    render() {
        return (
            <>
                <SpacePremiumForm step="1">
                    <Container className="justify-content-center space-premium-ctr-landing">
                        <h1>Upgrade to Premium</h1>
                        <h2>Simple and predictable pricing!</h2>
                        <div className="price-ctr">
                            <p>EUR <span>6/TB</span> month</p>
                        </div>
                        <ul>
                            <li><FontAwesomeIcon icon={faCheck} className={"space-premium-check-icons"}/> No additional fees</li>
                            <li><FontAwesomeIcon icon={faCheck} className={"space-premium-check-icons"}/> No minimum upload size limit</li>
                            <li><FontAwesomeIcon icon={faCheck} className={"space-premium-check-icons"}/> No minimum time to store a file</li>
                            <li><FontAwesomeIcon icon={faCheck} className={"space-premium-check-icons"}/> No upload charges</li>
                            <li><FontAwesomeIcon icon={faCheck} className={"space-premium-check-icons"}/> No download charges*</li>
                        </ul>
                        <p>* Fair usage policy applies. <br/>
                        The allowed download amount equals to your stored amount.
                        </p>
                    </Container>
                    <Container className="space-premium-btns align-content-center">
                        <Link className="primary lnk-btn btn-xl" href="/SpacePremium/2" label="Get started"/>
                    </Container>
                </SpacePremiumForm>
                </>
        )
    }
}

export default LandingStep