import React from 'react';
import queryString from 'query-string';
import {get, post} from '../../utils/request';
import Layout from "../../component/Layout/Layout";
import Container from "../../component/Container/Container";
import {Col, Form, Row} from "react-bootstrap";
import Button from "../../component/Button/Button";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Alert from "../../component/Alert/Alert";
import './activate.scss';

class Activate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            errorMessage: '',
            errors: {},
            loadingSuccess: false,
            showData: {},
            successMessage: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hasError = this.hasError.bind(this);
        this.loadData = this.loadData.bind(this);
        this.showError = this.showError.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const activationHash = queryString.parse(this.props.location.search).h;
        if (!activationHash) {
            this.setState({errorMessage: "Error: Account activation hashcode is missing."});

            return;
        }

        const [res, err] = await get(this, "/account/activation/" + activationHash);
        if (err) {
            this.setState({errorMessage: "" + err});
            this.setState({errors: {}});

            return;
        }
        const data = {
            hash: activationHash,
            name: res.data.name
        };

        const showData = {
            email: res.data.email,
            company: res.data.company,
            partnerName: res.data.partnerName,
        };

        this.setState({data: data});
        this.setState({showData: showData});
        this.setState({loadingSuccess: true});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return;
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            switch (field) {
                case "name":
                    error.message = "Please enter a name";
                    break;
                case "pass":
                    error.message = "Please enter a " + field.toLowerCase() + "word at least 10 characters long";
                    break;
            }
        }
    }
    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});
        this.setState({successMessage: ""});

        const [res, err] = await post(this, "/account/activation", this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        this.setState({errorMessage: "Cannot activate. An account with this hash and correct status was not found, the link is wrong or expired."});

                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors;
                        this.setState({errors: errors});

                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});

                        return;
                }
            }
            this.setState({errorMessage: err});

            return;
        }

        this.props.history.push('/activateDone');
    }

    render() {
        const { data, errorMessage, loadingSuccess, showData, successMessage } = this.state;

        return <Layout activeMenu="">
            <div className="activate-ctr">
                <h1>Account activation</h1>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {loadingSuccess &&
                    <>
                        <Container>
                            <h2>Activate</h2>
                            <Form onSubmit={e => this.handleSubmit(e)}>
                            <Form.Group as={Row} controlId="formEmail">
                                <Form.Label column sm="2">Email</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        defaultValue={showData.email}
                                        disabled
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formCompany">
                                <Form.Label column sm="2">Company</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        name="company"
                                        defaultValue={showData.company}
                                        disabled
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPartnerName">
                                <Form.Label column sm="2">Partner Name</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        name="partnerName"
                                        defaultValue={showData.partnerName}
                                        disabled
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formName">
                                <Form.Label column sm="2">Name</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        defaultValue={data.name}
                                        isInvalid={this.hasError("name")}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{this.showError("name")}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPass">
                                <Form.Label column sm="2">Password</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="password"
                                        name="pass"
                                        onChange={this.handleChange}
                                        isInvalid={this.hasError("pass")}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{this.showError("pass")}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            </Form>
                        </Container>
                        <div className="d-flex">
                            <Button label="Activate" faIcon={faCheckCircle} className="primary btn btn-right btn-lg" onClick={this.handleSubmit}/>
                        </div>
                    </>
                }
            </div>
        </Layout>;
    }
}

export default Activate