import React from 'react'
import {faCog, faUserSecret} from '@fortawesome/free-solid-svg-icons'
import Tabs from "../Tabs/Tabs";

function SettingsNav() {
    return (
        <Tabs>
            <div faIcon={faCog} exactTo={"/settings"} label="General">
            </div>
            <div faIcon={faUserSecret} exactTo={"/settings/security"} label="Security">
            </div>
        </Tabs>
    );
}

export default SettingsNav

