import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import "./Menu.scss";
import {faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const Menu = ({userEmail}) => {

    let navToggle = "";

    if(userEmail) {
        navToggle = (
            <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <span>{userEmail}</span>
                        <a href="/signOut"><FontAwesomeIcon className="icon" icon={faArrowRightFromBracket} />Sign out</a>                    </Nav>
                </Navbar.Collapse>
            </>
        )
    }

    return <Navbar className="navbar-full sticky-top" expand="lg">
        <Navbar.Brand href="/"><img className="nav-logo" src="/image/Logo_Storadera.svg" alt="Storadera logo"/></Navbar.Brand>
        {navToggle}
    </Navbar>
}

export default Menu;