import React from 'react';
import Tab from 'react-bootstrap/Tab';
import {Nav} from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const Steps = ({step}) => {

    let links = [
        {link: "/SpacePremium/"},
        {link: "/SpacePremium/2"},
        {link: "/SpacePremium/3"},
    ];

    let navItems = links.map((value, index) => (

        <Nav.Item className = {(index < step) ? "completed progressbar " : "progressbar"}>
            <NavLink
                className = {(index < step) ? "inactive-link nav-link completed" : "inactive-link nav-link"}
                exact to = {value.link}
                activeClassName = "active nav-link"
                onClick={ (e) => e.preventDefault() }
            >
                {((index+1) < step) ? <span><FontAwesomeIcon icon={faCheckCircle} className={"check-icon"} size="2x"/></span> : <span className={"icon-outer position-relative"}><FontAwesomeIcon icon={faCircle} size="2x"/><span className={"progress-icon"}>{index+1}</span></span> }
            </NavLink>
        </Nav.Item>
    ))

    return (
        <Tab.Container>
            <Nav className="multistep-bar">
                {navItems}
            </Nav>
        </Tab.Container>
    );
}
export default Steps

