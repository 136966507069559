import React from 'react';
import {post} from "../../utils/request";
import {Modal, Row, Col, Form} from "react-bootstrap";
import Button from "../../component/Button/Button";
import {faTrash, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import Alert from "../../component/Alert/Alert";

const modalTitle = "Delete bucket";

class DeleteBucket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            location: props.location,
            hasObjects: props.hasObjects,
            show: false,
            successMessage: "",
            dataDeleteConfirmed: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDeleteConfirmChange = this.handleDeleteConfirmChange.bind(this);
    }

    handleShow() {
        this.setState({show: true});
    }

    handleClose() {
        this.setState({show: false});
    }

    handleDeleteConfirmChange(event) {
        this.setState({
            dataDeleteConfirmed: event.target.checked
        });
    }

    async sendDeleteRequest(e) {
        if (this.state.hasObjects && !this.state.dataDeleteConfirmed) {
            this.setState({errorMessage: "Please confirm, that you want to delete all the data with the bucket"});
            return;
        }

        this.setState({successMessage: ""});

        const [res, err] = await post(this, "/auth/bucket/delete/" + this.props.bucket + "/in/" + this.state.location);
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 409:
                        this.setState({errorMessage: "The bucket is not empty. Please empty the bucket first and then try again!"});
                        return;
                    case 400:
                        this.setState({errorMessage: "Invalid bucket name given. Please try again."});
                        return;
                    case 500:
                        this.setState({errorMessage: "Oops, something went wrong. Please try again later."});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.props.updateDeleted(this.props.bucket);
        this.setState({show: false});
    }

    render() {
        const {errorMessage, show} = this.state;
        const {bucket} = this.props;

        return (
            <>
                <Button className="btn secondary-outline" faIcon={faTrash} label="Delete" onClick={this.handleShow}/>
                <Modal
                    onHide={this.handleClose}
                    show={show}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        <p>Are you sure you want to delete bucket <b>{bucket}</b>?</p>
                        {this.state.hasObjects && <p>
                            <Form.Group as={Row} controlId="formBasicCheckbox">
                                <Col sm="1">
                                    <Form.Check type="checkbox" name="confirmDataDelete"
                                                defaultChecked={this.state.dataDeleteConfirmed}
                                                onChange={this.handleDeleteConfirmChange}/>
                                </Col>
                                <Form.Label column sm="11">This bucket is not empty. I hereby confirm I want to delete
                                    bucket with all the data inside.</Form.Label>
                            </Form.Group>
                        </p>}
                        <Row className="float-right mr-auto">
                            <Button className="cancel-outline btn mr3" faIcon={faXmarkCircle} label="Cancel"
                                    onClick={this.handleClose}/>
                            <Button className="secondary btn" faIcon={faTrash} label="Delete"
                                    onClick={e => this.sendDeleteRequest(e)}/>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    };
}

export default DeleteBucket;