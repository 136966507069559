import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import "./Tabs.scss";

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    }

render() {
    const {
        props: {
            children,
        },
    } = this;

    return (
        <div className="tab-list">
                {children.map((child, index) => {
                    const { exactTo, faIcon, label } = child.props;

                    return (
                        <Tab
                            key={index}
                            exactTo={exactTo}
                            faIcon={faIcon}
                            label={label}
                        />
                    );
                })}
        </div>
    );
}
}

export default Tabs;