import React, {Component} from "react";
import SpacePremiumForm from "./SpacePremiumForm";
import AddStripe from "../billing/AddStripe";
import {Container} from "react-bootstrap";
import BillingSecurityText from "../../component/BillingSecurityText/BillingSecurityText";
import Button from "../../component/Button/Button";
import {get, post} from "../../utils/request";
import Alert from "../../component/Alert/Alert";
import Link from "../../component/Link/Link";
import "./spacePremium.scss";


export class PaymentStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            lastSubmitTime: null,
            hrefLink: '/SpacePremium/4',
            formSuccess: false,
            buttonEnabled: false,
        };
        this.loadData = this.loadData.bind(this);
        this.outsideFormSubmit = this.outsideFormSubmit.bind(this);
        this.postPremium = this.postPremium.bind(this);
        this.propagateSuccessConfirmation = this.propagateSuccessConfirmation.bind(this);
        this.propagateButtonEnabled = this.propagateButtonEnabled.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            return
        }
        this.setState({data: res.data});
    }


    async outsideFormSubmit() {
        this.setState({lastSubmitTime: new Date()})
    }

    async propagateSuccessConfirmation() {
        await this.postPremium();
    }

    propagateButtonEnabled(enabled) {
        if(this.state.buttonEnabled !== enabled) {
        this.setState({buttonEnabled: enabled});
        }
    }

    async postPremium() {
        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});
        const [res, err] = await post(this, "/auth/account/package");
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 409:
                        this.setState({errorMessage: "Already added as paying customer"});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({successMessage: "Converted to paying customer"});
        if(this.state.hrefLink) {
            this.props.history.push(this.state.hrefLink);
        }
    }

    render() {
        const saveButtonText = !this.state.buttonEnabled  ? "Saving" : "Go Premium"
        return (
            <>
            <SpacePremiumForm step="3">
                <Container className="space-premium-forms-ctr">
                    <h2>Add payment method</h2>
                    {this.state.successMessage && <p className="alert-size" variant="success">{this.state.successMessage}</p>}
                    {this.state.errorMessage && <p className="alert-size" variant="danger">{this.state.errorMessage}</p>}
                    <AddStripe
                        history={this.props.history}
                        lastSubmitTime={this.state.lastSubmitTime}
                        hrefLink={this.state.hrefLink}
                        propagateSuccessConfirmation={this.propagateSuccessConfirmation}
                        propagateButtonEnabled={this.propagateButtonEnabled}
                        alertClass={"alert-size"}
                    />
                    <BillingSecurityText/>
                </Container>
                <div className="d-flex justify-content-between">
                    <Link className="primary-outline lnk-btn btn-lg" label="Back" href="/SpacePremium/2"/>
                    <Button className="primary btn btn-lg"
                            label={saveButtonText}
                            onClick={this.outsideFormSubmit}
                            disabled={!this.state.buttonEnabled}
                    >
                    </Button>
                </div>
            </SpacePremiumForm>
            </>
        )
    }
}
export default PaymentStep
