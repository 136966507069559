import React from 'react';
import {get, post} from '../../utils/request'
import { Spinner } from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Layout from "../../component/Layout/Layout";
import DeleteKey from "./DeleteKey";
import "../../component/Table/Table.scss"
import {faBan, faCheckCircle, faKey} from "@fortawesome/free-solid-svg-icons";

class Key extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            errorMessage: "",
            loading: true,
            loadingSuccess: false
        };
        this.delete = this.delete.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/overview");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({data: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    componentDidMount() {
        this.loadData();
    }

    async sendKeyRequest(key, action) {
        const [_, err] = await post(this, "/auth/s3Key/" + action + "/" + key, {});
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.loadData();
    }

    activate(key, e) {
        this.sendKeyRequest(key, "activate")
    }

    deactivate(key, e) {
        this.sendKeyRequest(key, "deactivate")
    }

    delete(key, e) {
        this.sendKeyRequest(key, "delete")
    }

    render() {

        const { data, errorMessage, loading, loadingSuccess } = this.state;
        const { history } = this.props;

        const homepageUrl = `${process.env.REACT_APP_HOMEPAGE_URL}`;

        let keys = [];
        if (this.state.data && this.state.data.s3Keys) {
            keys = this.state.data.s3Keys.map((item, index) =>
                <tr key={item.key.toString()}>
                    <td data-label="#">{index + 1}</td>
                    <td data-label="Key">{item.key}</td>
                    <td data-label="Status">{item.status}</td>
                    <td>
                        {item.status == "inactive" && <Button
                            className="success-outline btn btn-md"
                            faIcon={faCheckCircle}
                            label="Activate"
                            onClick={this.activate.bind(this, item.key)}/>}
                        {item.status == "active" && <Button
                            className="primary-outline btn btn-md"
                            faIcon={faBan}
                            label="Deactivate"
                            onClick={this.deactivate.bind(this, item.key)}/>}
                    </td>
                    <td>
                        <DeleteKey delete={this.delete} s3Key={item.key}/>
                    </td>
                </tr>
            );
        }

        return <Layout activeMenu="/key" isLoggedIn={true}>
            <h1>Keys</h1>
            {loading &&
            <>
                <Spinner animation="border" variant="primary"/> Loading keys...
                <br/><br/>
            </>
            }
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {loadingSuccess &&
            <>
                {data.s3Keys && (data.s3Keys.length < 2) &&
                    <div className="d-flex justify-content-end">
                        <Button className="primary btn btn-margin" faIcon={faKey} label="Create new
                        key" onClick={() => history.push('key/NewKey')}/>
                    </div>
                }
                {(keys.length !== 0) && data.s3Keys &&
                <table className="table-ctr">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Key</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>{keys}</tbody>
                </table>
                }

                {data.s3Keys && (data.s3Keys.length >= 2) &&
                <p>There can be only 2 keys at any given time</p>}
                <br/>
                <Alert variant="info">
                    <b>For technical configurations, please consult our documentation</b> <br/>
                    <a href={homepageUrl+'/integrations'} target="_blank">Integration manuals</a> <br/>
                    <a href={homepageUrl+'/docs/regions'} target="_blank">Regions</a>
                </Alert>
            </>
            }
        </Layout>;
    }
}

export default Key