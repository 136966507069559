import React from 'react';
import { Modal } from "react-bootstrap";
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import MfaLoginVerifyCodeForm from "./MfaLoginVerifyCodeForm";

const modalTitle = "Multi-factor authentication"

class MfaLoginModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginCode: '',
        };
        this.passLoginCode = this.passLoginCode.bind(this);
    }

    passLoginCode(newValue) {
        this.setState({loginCode: newValue})
    }

    render() {
        const { mfaFailed, mfaErrMessage, modal, modalClose } = this.props;

        return (
            <>
                <Modal
                    animation={false}
                    className="main-modal"
                    onHide={modalClose}
                    show={modal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!mfaFailed && (
                            <>
                                <MfaLoginVerifyCodeForm {...this.props}/>
                            </>
                        )}
                        {mfaFailed && (
                            <>
                                <Alert variant="danger-text">{mfaErrMessage}</Alert>
                                <div className="d-flex mt3">
                                    <Button className="btn cancel-outline btn-md btn-right" label="Close" onClick={modalClose}/>
                                </div>
                            </>
                        )}
                    </Modal.Body>
                </Modal>
            </>
        );
    };
}
export default MfaLoginModal;