import React from 'react'
import Tabs from "../Tabs/Tabs";
import {
    faCreditCard,
    faDollarSign,
    faFileInvoiceDollar,
    faTags
} from '@fortawesome/free-solid-svg-icons'

function BillingNav() {
    return (
        <Tabs>
            <div faIcon={faCreditCard} exactTo={"/billing"} label="Billing">
            </div>
            <div faIcon={faFileInvoiceDollar} exactTo={"/billing/invoices"} label="Invoices">
            </div>
            <div faIcon={faTags} exactTo={"/billing/vouchers"} label="Vouchers">
            </div>
        </Tabs>
    );
}

export default BillingNav

