import React from 'react'
import Layout from "../../component/Layout/Layout";
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import {get} from "../../utils/request";
import {Spinner} from "react-bootstrap";
import BillingNav from "../../component/BillingNav/BillingNav";
import "../../component/Table/Table.scss"
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import SkipIfManagedByPartner from "../../component/SkipIfManagedByPartner/SkipIfManagedByPartner";
class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            successMessage: "",
            errorMessage: "",
            methods: [],
            pendingAuthentication: false,
            loadingSuccess: false,
        };
        if (props.location && props.location.state && props.location.state.message) {
            this.state.successMessage = props.location.state.message;

            // clear param
            const locationState = {...props.location.state};
            delete locationState.message;
            props.history.replace({...props.location, state: locationState});
        }
        this.loadData = this.loadData.bind(this);
        this.loadPendingAuthentication = this.loadPendingAuthentication.bind(this);
    }

    async componentDidMount() {
        await this.loadPendingAuthentication()
        await this.loadData();

    }

    async loadData() {
        const [res, err] = await get(this, "/auth/payment/methodList");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({methods: res.data.Methods});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    async loadPendingAuthentication() {
        return // todo - temporary disable
        const [res, err] = await get(this, "/auth/payment/pendingAuthentication");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({pendingAuthentication: res.data.StripePending === true});
    }

    render() {
        const { errorMessage, loading, loadingSuccess, methods, pendingAuthentication, successMessage } = this.state;
        const { history } = this.props;

        return <Layout activeMenu="/billing" isLoggedIn={true}>
            <h1>Billing</h1>
            <BillingNav/>
            <SkipIfManagedByPartner>
                {loading &&
                    <>
                        <Spinner animation="border" variant="primary"/> Loading cards...
                        <br/><br/>
                    </>
                }
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {loadingSuccess &&
                    <>
                        <h2>Payment Methods</h2>
                        <div className="d-flex">
                            <Button className="primary btn btn-margin btn-right" faIcon={faCreditCard} label="Add new payment method" onClick={() => history.push('/billing/addPaymentMethod')}/>
                        </div>

                        {pendingAuthentication && <Alert variant="warning">
                            <b>NB! Payment authentication required!</b>
                            <br/><br/>Your bank requires extra authentication for your payment.
                            <br/><br/><Button href="/billing/pendingAuthentication">Do the authentication</Button>
                        </Alert>}
                        {methods.length === 0 && this.state.loaded &&
                            <Alert variant="primary">
                                Please add a payment method.
                            </Alert>
                        }
                        {methods.length > 0 &&
                            <table className="table-ctr">
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Details</th>
                                </tr>
                                </thead>
                                <tbody>
                                {methods.map((value, index) => {
                                    return <tr key={index}>
                                        <td data-label="Type">{value.Brand}</td>
                                        <td data-label="Details">
                                            Card <b>************{value.Last4}</b>
                                            &nbsp;&nbsp;&nbsp; Expire on {value.ExpMonth}/{value.ExpYear}
                                            {value.IsDefault && <b>&nbsp;&nbsp;&nbsp; Default</b>}
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        }
                    </>
                }
            </SkipIfManagedByPartner>

        </Layout>;
    }
}

export default Billing