import React from 'react'
import Alert from "../../component/Alert/Alert";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import './password.scss';

class PasswordDone extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Layout activeMenu="/signIn">
            <div className="password-ctr">
                <h1>Password reset</h1>
                <Alert variant="success">Your password has been successfully reset.</Alert>
                <p>Please use your new password to sign in.</p>
                <Link className="primary lnk-btn btn-xl" href="/signIn" label="Go to sign in"/>
            </div>
        </Layout>;
    }
}

export default PasswordDone