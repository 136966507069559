import React from 'react';
import Select from 'react-select'


const regularStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow:  state.isFocused ? '0 0 0 .2rem rgba(0,123,255,.25)' : provided.boxShadow,
        borderColor: state.isFocused ? '#80bdff' : provided.borderColor,
        outline: state.isFocused ? 0 : provided.outline,
        backgroundColor: state.isFocused ? '#fff' : provided.backgroundColor,
        color: state.isFocused ? '#495057' : provided.color,
        '&:hover': {
        },

    }),
};

const errorStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: '#dc3545',
        boxShadow:  state.isFocused ? '0 0 0 .2rem rgba(220, 53, 69,.25)' : provided.boxShadow,
        '&:hover': {
        },

    }),
};

export default class DropdownSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            onChange: props.onChange,
            defaultValue: props.defaultValue,
        };
         if (props.selected) {
            this.state.selected = {
                value: props.selected,
                label: props.selected
            }
         }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.state.onChange(event.value);
    }

    render() {
        return <Select
            name={this.props.name}
            styles={!this.props.hasError ? regularStyles : errorStyles}
            options={this.state.data}
            defaultValue={this.state.defaultValue}
            onChange={this.handleChange}
            value={this.state.selected}
            hasError={this.props.hasError}
        />
    }
}