import React from 'react';

export function MfaErrorHandler(errData) {
    let mfaFailed = false;
    let resendLinkBool = false;
    let message='';

    switch (errData) {
        case "mfa_code_required":
            message = "Multi-factor authentication required.";
            return {message, resendLinkBool};
        case "mfa_wrong_code":
            message = "Invalid code, please enter a valid code.";
            return {message, resendLinkBool};
        case "mfa_too_many_attempts":
            message = "The code has been entered incorrectly too many times. Would you like a new code to be sent to you?";
            resendLinkBool = true;
            return {message, resendLinkBool};
        case "mfa_active_not_found":
            message = "Your multi-factor authentication has expired. Would you like a new code to be sent to you?";
            resendLinkBool = true;
            return {message, resendLinkBool};
        case "mfa_too_many_new_attempts":
            message = "You have attempted too many multi-factor authentications, please try again later.";
            mfaFailed = true;
            return {message, resendLinkBool, mfaFailed};
    }
}

export default MfaErrorHandler;