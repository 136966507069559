import React from 'react';
import {get} from '../../utils/request';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import SkipIfManagedByPartner from "../../component/SkipIfManagedByPartner/SkipIfManagedByPartner";
import UsageHistory from "../../component/UsageHistory/UsageHistory";
import { Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
import {faArrowCircleRight, faKey} from '@fortawesome/free-solid-svg-icons'
import './home.scss';

const filesize = require('filesize');

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            usage: 0,
            usageLimit: 0,
            data: {},
            errorMessage: "",
            loading: true,
            loadingSuccess: false,
            showData: {
                paid: '',
            },
        }
        this.loadUsage = this.loadUsage.bind(this);
        this.loadKeys = this.loadKeys.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    async loadUsage() {
        const [res, err] = await get(this, "/auth/account/usage");
        if (err) {
            if (err.response && err.response.status && err.response.status === 404) {
                this.setState({errorMessage: "Data not available at this moment"});
            } else {
                this.setState({errorMessage: "error: " + err});
            }
            this.setState({loading: false});
            return
        }
        this.setState({usage: res.data.usage, usageLimit: res.data.usageLimit})
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    async loadKeys() {
        const [res, err] = await get(this, "/auth/overview");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/settings/get");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({errors: {}})
            return
        }
        const data = res.data;
        const showData = {
            paid: data.paid,
        };
        delete data.paid;

        this.setState({showData: showData});
        this.setState({loadingSuccess: true});
    }

    componentDidMount() {
        this.loadUsage();
        this.loadKeys();
        this.loadData();
    }

    render() {
        const { data, errorMessage, loading, loadingSuccess, showData, usageLimit, usage } = this.state;

        let usagePercent = 0;

        if ((usage != 0)) {
            usagePercent = Math.round((usage / usageLimit) * 100);
        }

        let usageStatus = "success";

        if ((usagePercent) > 75) {
            usageStatus = "warning";
        }

        if ((usagePercent) > 90) {
            usageStatus = "danger";
        }

        let availableStorage = usageLimit - usage;

        if (availableStorage < 0) {
            availableStorage = 0;
        }

        let usageComponent;
            usageComponent = <>
                <Container className="">
                    <h2>Your storage</h2>
                    <Row>
                        <Col sm="2">
                            <label>Your current plan</label>
                        </Col>
                        <Col sm="10">
                            <p>{showData.paid ? "Space Premium" : "Space Trial"}</p>
                        </Col>
                    </Row>
                    <label>Current usage amount</label>
                    <div className="progress-bar-ctr d-flex justify-content-between">
                        <div>
                            <ProgressBar variant={usageStatus} now={usagePercent} max={100}/>
                            <p className="mb4">{filesize(usage) + " of " + filesize(usageLimit)} ({usagePercent}%)
                                used</p>
                        </div>
                    </div>
                    <div className="go-premium-lnk-ctr">
                        <Row>
                            <Col md="2">
                                <label>Free space remaining</label>
                            </Col>
                            <Col md="5">
                                <p>{filesize(availableStorage)}</p>
                            </Col>
                            <Col sm>
                                {(!showData.paid) &&
                                    <SkipIfManagedByPartner>
                                        <Row className="premium-ctr">
                                            <h3>Need more space?</h3>
                                            <Link className="primary lnk-btn btn-lg" faIcon={faArrowCircleRight} href='/SpacePremium' label="Upgrade to Premium"/>
                                        </Row>
                                    </SkipIfManagedByPartner>
                                }
                            </Col>
                        </Row>
                    </div>
                </Container>
                <UsageHistory/>
            </>;

        return <Layout activeMenu="/" isLoggedIn={true}>
            <>
                <h1>Home</h1>
                {loading &&
                    <>
                        <Spinner animation="border" variant="primary"/> Loading...
                        <br/><br/>
                    </>
                }
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {loadingSuccess &&
                    <>
                        {data.s3Keys && (data.s3Keys.length < 1) &&
                            <Alert variant="info">
                                <h2>Seems you have no keys. Create one now!</h2>
                            <br/>
                                <Button className="btn primary" label="Create new
                                    key" faIcon={faKey} onClick={() => this.props.history.push('/key/NewKey')}/>
                            </Alert>
                        }
                        {usageComponent}
                    </>
                }
            </>
        </Layout>;
    }
}

export default Home



