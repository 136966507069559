import React from 'react';
import "./Container.scss";

const Container = ({children, variant, width}) => {

        let className = (variant === "borderless") ? "ctr" : "ctr-bordered ctr";

        return (
            <div className={className} style={{maxWidth: width}}>
                {children}
            </div>
        );
}

export default Container;