import React from 'react';
import '../Badge/Badge.scss';

const Badge = ({ className, label }) => {

    let classNames="badge " + className;

    return (
        <span
            className={classNames}
        >
            {label}
        </span>
    );
}

export default Badge;